import React, {useContext, useEffect, useState} from "react"
import styles from "./Requests.module.scss"
import {ServiceContext} from "../../context/service/serviceContext";
import CustomCard from "../../hoc/Card/CustomCard";
import CustomDataGrid from "../../components/Layout/CustomDataGrid/CustomDataGrid";
import NewRequest from "../../components/User/NewRequest/NewRequest";
import {CircularProgress, IconButton} from "@mui/material";
import PrintIcon from "@mui/icons-material/Print";
import PrintRequestNewConnection from "../../components/Requests/PrintRequest/PrintRequestNewConnection";
import PrintRequest from "../../components/Requests/PrintRequest/PrintRequest";

const clearCreateRequestForm = () => {
    return {
        type: '',
        vlan: '',
        phone: '',
        comment: '',
        userLogin: '',
        userFio: '',
        userPhone: '',
        userIp: '',
        city: '',
        street: '',
        house: '',
        room: ''
    }
}

const Requests = props => {
    const {getAllRequest, clearAllRequests, getAllRequestTypes, createRequest, state} = useContext(ServiceContext)

    const [search, setSearch] = useState({
        createRequestForm: clearCreateRequestForm()
    })

    const [request, setRequest] = useState(null)

    const handlePrintRequestClick = id => {
        const req = state.requestList.find(x => x.id === id)
        setRequest(req)
        console.log(req)
        setTimeout(() => {
            window.print()
        }, 2000)
        setTimeout(() => {
            setRequest(null)
        }, 3000)
    }

    const inputChangeHandler = (formName, name, e) => {
        const form = {...search[formName], [name]: e.target.value}
        setSearch({
            ...search,
            [formName]: form
        })
    }

    const clickCreateHandler = () => {
        createRequest(search.createRequestForm)
        setSearch({
            createRequestForm: clearCreateRequestForm()
        })
    }

    useEffect(() => {
        clearAllRequests()
        getAllRequestTypes()
        getAllRequest()

        return () => {
            clearAllRequests()
        }
        // eslint-disable-next-line
    }, [])

    const columns = [
        { field: 'action', headerName: '', width: 65, renderCell: ({row}) => !!request
                ? <CircularProgress color="warning" />
                : <IconButton color="warning" onClick={() => handlePrintRequestClick(row.id)}>
                    <PrintIcon />
                </IconButton>},
        { field: 'id', headerName: '#', width: 50 },
        { field: 'userLogin', headerName: 'User Login', width: 150 },
        { field: 'userFio', headerName: 'User FIO', width: 400 },
        { field: 'userPhone', headerName: 'Phone', width: 200 },
        { field: 'status', headerName: 'Status', width: 200 },
        { field: 'typeName', headerName: 'Type', width: 400 },
        { field: 'vlan', headerName: 'Vlan', width: 100 },
        { field: 'userIp', headerName: 'IP', width: 200 },
        { field: 'comment', headerName: 'Comment', width: 400 },
        { field: 'createdAtString', headerName: 'Date', width: 200 },
        { field: 'city', headerName: 'Address', width: 400, renderCell: ({row}) => `${row.city} ${row.street} ${row.house} ${row.room}` },
    ]

    return <div className={styles.Requests}>
        <h1 className="no-print">Requests</h1>
        <div className={['no-print', styles.grid].join(" ")}>
            <CustomCard>
                <NewRequest
                    createRequestForm={search.createRequestForm}
                    requestTypes={state.requestTypes}
                    inputChangeHandler={inputChangeHandler}
                    clickCreateHandler={clickCreateHandler}
                    onlyNewConnection={true}
                />
            </CustomCard>
        </div>
        <div className={['no-print', styles.grid].join(" ")}>
            <div className={styles.w100}>
                <CustomCard>
                    <div style={{ height: 700, width: '100%', paddingBottom: "50px"}}>
                        <CustomDataGrid
                            rows={state.requestList}
                            columns={columns}
                            pageSize={25}
                        />
                    </div>
                </CustomCard>
            </div>
        </div>

        <div className="print">
            {request?.typeName.includes("Заявка на підключення") ? <>
                <PrintRequestNewConnection request={request} />
            </>
            : <>
                <PrintRequest request={request} />
                <hr />
                <PrintRequest request={request} />
            </>}
        </div>
    </div>
}

export default Requests