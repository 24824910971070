export const SHOW_ALERT = "SHOW_ALERT"
export const HIDE_ALERT = "HIDE_ALERT"

export const SHOW_CALL = "SHOW_CALL"
export const HIDE_CALL = "HIDE_CALL"

export const AUTH_LOGIN = "AUTH_LOGIN"
export const AUTH_LOGOUT = "AUTH_LOGOUT"

export const ACTIVATION_SEARCH_USER = "ACTIVATION_SEARCH_USER"
export const ACTIVATION_ACTIVATE_USER = "ACTIVATION_ACTIVATE_USER"
export const ACTIVATION_SET_LOADING = "ACTIVATION_SET_LOADING"
export const ACTIVATION_CLEAR_LOADING = "ACTIVATION_CLEAR_LOADING"
export const ACTIVATION_CLEAR_ACTIVATION = "ACTIVATION_CLEAR_ACTIVATION"
export const ACTIVATION_SET_FORM = "ACTIVATION_SET_FORM"

export const BDCOM_REQUEST_SET_FORM = "BDCOM_REQUEST_SET_FORM"
export const BDCOM_SEARCH_SET_FORM = "BDCOM_SEARCH_SET_FORM"
export const ONU_GET_BDCOM_LIST = "ONU_GET_BDCOM_LIST"
export const ONU_SEARCH_BDCOM_ONU_BY_MAC = "ONU_SEARCH_BDCOM_ONU_BY_MAC"
export const ONU_GET_BDCOM_ONU_SIGNAL = "ONU_GET_BDCOM_ONU_SIGNAL"
export const ONU_REMOVE_BDCOM_ONU = "ONU_REMOVE_BDCOM_ONU"
export const ONU_ADD_BDCOM_ONU_VLAN = "ONU_ADD_BDCOM_ONU_VLAN"
export const ONU_RELOAD_BDCOM_ONU = "ONU_RELOAD_BDCOM_ONU"
export const ONU_RELOAD_BDCOM_PON_PORT = "ONU_RELOAD_BDCOM_PON_PORT"
export const ONU_CLEAR_BDCOM_ALL_ONU = "ONU_CLEAR_BDCOM_ALL_ONU"


export const ZTE_REQUEST_SET_FORM = "ZTE_REQUEST_SET_FORM"
export const ONU_GET_ZTE_LIST = "ONU_GET_ZTE_LIST"
export const ONU_GET_CREATE_ZTE_FORM = "ONU_GET_CREATE_ZTE_FORM"
export const CHANGE_ONU_CREATE_ZTE_FORM = "CHANGE_ONU_CREATE_ZTE_FORM"
export const SEND_ONU_CREATE_ZTE_REQUEST = "SEND_ONU_CREATE_ZTE_REQUEST"

export const JUNIPER_REQUEST_SET_FORM = "JUNIPER_REQUEST_SET_FORM"
export const ONU_GET_JUNIPER_LIST = "ONU_GET_JUNIPER_LIST"

export const DEVICE_GROUP_SET_FORM = "DEVICE_GROUP_SET_FORM"
export const DEVICE_GROUP_GET_ALL = "DEVICE_GROUP_GET_ALL"
export const DEVICE_GROUP_CREATE = "DEVICE_GROUP_CREATE"
export const DEVICE_GROUP_UPDATE = "DEVICE_GROUP_UPDATE"
export const DEVICE_GROUP_DELETE = "DEVICE_GROUP_DELETE"

export const DEVICE_SET_FORM = "DEVICE_SET_FORM"
export const DEVICE_GET_ALL = "DEVICE_GET_ALL"
export const DEVICE_CREATE = "DEVICE_CREATE"
export const DEVICE_UPDATE = "DEVICE_UPDATE"
export const DEVICE_DELETE = "DEVICE_DELETE"

export const USER_SET_CHANGE_FORM = "USER_SET_CHANGE_FORM"
export const USER_SET_PASSWORD_FORM = "USER_SET_PASSWORD_FORM"
export const USER_GET_ALL_BY_ROLE = "USER_GET_ALL_BY_ROLE"
export const USER_CREATE = "USER_CREATE"
export const USER_UPDATE = "USER_UPDATE"
export const USER_DELETE = "USER_DELETE"
export const USER_UPDATE_BALANCE = "USER_UPDATE_BALANCE"
export const USER_CHANGE_PASSWORD = "USER_CHANGE_PASSWORD"

export const REQUEST_TYPES_GET_ALL = "REQUEST_TYPES_GET_ALL"

export const REQUEST_SET_FORM = "REQUEST_SET_FORM"
export const REQUEST_GET_ALL = "REQUEST_GET_ALL"
export const REQUEST_GET_ALL_BY_USER_LOGIN = "REQUEST_GET_ALL_BY_USER_LOGIN"
export const REQUEST_CREATE = "REQUEST_CREATE"

export const STATISTICS_GET_TOTALS = "STATISTICS_GET_TOTALS"

export const CUSTOMER_SEARCH = "CUSTOMER_SEARCH"
export const CUSTOMER_CLEAR = "CUSTOMER_CLEAR"

export const CALL_HISTORY_GET_ALL = "CALL_HISTORY_GET_ALL"
export const CALL_HISTORY_GET_BY_USER_LOGIN = "CALL_HISTORY_GET_BY_USER_LOGIN"
export const CALL_HISTORY_SET_PROCESSED = "CALL_HISTORY_SET_PROCESSED"
export const CALL_HISTORY_SET_COMMENT = "CALL_HISTORY_SET_COMMENT"

export const USER_SIDE_GET_DATA = "USER_SIDE_GET_DATA"