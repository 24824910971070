import React from "react"
import {TextField} from "@mui/material"

const TextInput = props => {
    const type = !!props.type ? props.type : "text"

    return <TextField
        id={`outlined-${props.name}`}
        size="small"
        label={props.label}
        type={type}
        name={props.name}
        value={props.value}
        fullWidth={props.fullWidth === undefined ? true : props.fullWidth}
        onChange={props.onChange}
        className={props.className}
    />
}

export default TextInput