import React, {useContext, useEffect, useState} from "react"
import styles from "./Customer.module.scss"
import CustomCard from "../../hoc/Card/CustomCard"
import Loader from "../../components/Ui/Loader/Loader"
import SearchIcon from "@mui/icons-material/Search"
import TextInput from "../../components/Ui/TextInput/TextInput"
import {Button, CircularProgress, IconButton} from "@mui/material"
import {ServiceContext} from "../../context/service/serviceContext"
import CustomDataGrid from "../../components/Layout/CustomDataGrid/CustomDataGrid"
import NewRequest from "../../components/User/NewRequest/NewRequest"
import BillingUser from "../../components/User/BillingUser/BillingUser"
import {useHistory} from "react-router-dom"
import UserSide from "../../components/User/UserSide/UserSide"
import CallHistory from "../../components/User/CallHistory/CallHistory"
import PrintIcon from '@mui/icons-material/Print'
import PrintRequest from "../../components/Requests/PrintRequest/PrintRequest";

const Customer = props => {
    const history = useHistory()
    const {searchCustomer, createRequest, clearCustomer, callHistorySetProcessed, callHistorySetComment, getAllRequestTypes, state} = useContext(ServiceContext)
    const login = props.match.params?.login

    const clearCreateRequestForm = () => {
        return {
            type: '',
            vlan: '',
            phone: '',
            comment: ''
        }
    }

    const [search, setSearch] = useState({
        searchForm: {
            userAccount: state.customer ? state.customer.userLogin : login
        },
        createRequestForm: clearCreateRequestForm()
    })
    const [request, setRequest] = useState(null)

    const inputChangeHandler = (formName, name, e) => {
        const form = {...search[formName], [name]: e.target.value}
        setSearch({
            ...search,
            [formName]: form
        })
    }

    const clickSearchHandler = () => {
        history.push(`/customer/${search.searchForm.userAccount}`)
        searchCustomer(search.searchForm.userAccount)
    }

    const clickCreateHandler = () => {
        createRequest(search.createRequestForm)
        setSearch({
            searchForm: {
                userAccount: ''
            },
            createRequestForm: clearCreateRequestForm()
        })
    }

    const handlePrintRequestClick = id => {
        const req = state.requestList.find(x => x.id === id)
        setRequest(req)
        console.log(req)
        setTimeout(() => {
            window.print()
        }, 2000)
        setTimeout(() => {
            setRequest(null)
        }, 3000)
    }

    useEffect(() => {
        getAllRequestTypes()

        if (!!search.searchForm.userAccount)
            clickSearchHandler()

        return () => {
            clearCustomer()
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        return history.listen((location) => {
            if (!location.pathname.includes("customer"))
                return

            const newLogin = location.pathname.replace('/customer/', '')
            if (!!newLogin)
            {
                if (!newLogin.includes("customer"))
                {
                    inputChangeHandler('searchForm', "userAccount" , {target: {value: newLogin}})
                    searchCustomer(newLogin)
                }
                else
                {
                    setSearch({
                        searchForm: {
                            userAccount: ''
                        },
                        createRequestForm: clearCreateRequestForm()
                    })
                    clearCustomer()
                }
            }
        })
        // eslint-disable-next-line
    }, [history])

    const columns = [
        { field: 'id', headerName: '#', width: 50 },
        { field: 'action', headerName: '', width: 65, renderCell: ({row}) => !!request
                ? <CircularProgress color="warning" />
                : <IconButton color="warning" onClick={() => handlePrintRequestClick(row.id)}>
                <PrintIcon />
            </IconButton>},
        { field: 'userLogin', headerName: 'User Login', width: 150 },
        { field: 'userFio', headerName: 'User FIO', width: 400 },
        { field: 'userPhone', headerName: 'Phone', width: 200 },
        { field: 'status', headerName: 'Status', width: 200 },
        { field: 'typeName', headerName: 'Type', width: 400 },
        { field: 'vlan', headerName: 'Vlan', width: 100 },
        { field: 'userIp', headerName: 'IP', width: 200 },
        { field: 'comment', headerName: 'Comment', width: 400 },
        { field: 'createdAtString', headerName: 'Date', width: 200 },
        { field: 'city', headerName: 'Address', width: 400, renderCell: ({row}) => `${row.city} ${row.street} ${row.house} ${row.room}` },
    ]

    const handleSetProcessed = id => {
        console.log("handleSetProcessed", id)
        callHistorySetProcessed(id)
    }

    const handleSaveComment = (model) => {
        callHistorySetComment(model.id, model.comment)
    }

    return <div className={styles.Customer}>
        <h1 className="no-print">Customer</h1>

        <div className={['no-print', styles.container].join(" ")}>
            <div className={styles.row}>
                <div>
                    <div className={styles.row}>
                        <CustomCard title="Пошук">
                            <div className={styles.form}>
                                <TextInput
                                    label="Номер рахунку або ім’я"
                                    name="userAccount"
                                    value={search.searchForm.userAccount}
                                    onChange={e => inputChangeHandler('searchForm', "userAccount" ,e)}
                                />
                                <div className={styles.formGroupDown}>
                                    <Button
                                        variant="outlined"
                                        startIcon={<SearchIcon />}
                                        onClick={clickSearchHandler}
                                    >
                                        Шукати
                                    </Button>
                                </div>
                            </div>
                        </CustomCard>
                    </div>
                    <div className={styles.row} style={{marginTop: '16px'}}>
                        {state.customer
                            ? <NewRequest
                                createRequestForm={search.createRequestForm}
                                requestTypes={state.requestTypes}
                                inputChangeHandler={inputChangeHandler}
                                clickCreateHandler={clickCreateHandler}
                            />
                            : null}
                    </div>
                </div>
                <div>
                    <div className={styles.col}>
                        <BillingUser data={state.customer} />
                    </div>
                </div>
            </div>
            <div className={styles.row}>
                <UserSide data={state.customerUserSide} />
            </div>

            {state.loading
                ? <CustomCard title="Триває пошуку">
                    <Loader />
                </CustomCard>
                : state.requestList.length > 0 ? <div className={styles.result}>
                        <CustomCard title="Останні звернення">
                            <div style={{ height: 500, width: '100%', paddingBottom: "50px"}}>
                                <CustomDataGrid
                                    rows={state.requestList}
                                    columns={columns}
                                    pageSize={25}
                                />
                            </div>
                        </CustomCard>
                    </div>
                    : null}

            {state.customer
                ? <CallHistory
                        data={state.customerCallHistory}
                        canEdit={true}
                        onSetProcessed={handleSetProcessed}
                        onSaveComment={handleSaveComment}
                    />
                : null}
        </div>

        <div className="print">
            <PrintRequest request={request} />
            <hr />
            <PrintRequest request={request} />
        </div>
    </div>
}

export default Customer