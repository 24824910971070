import React, {useContext} from "react"
import styles from "./Alert.module.scss"
import {AlertContext} from "../../../context/alert/alertContext"

const Alert = () => {
    const {alert, hide} = useContext(AlertContext)
    if (!alert)
        return null

    const cls = ['no-print', styles.Alert]
    if (alert.type)
        cls.push(styles[alert.type])

    return <div className={cls.join(" ")}>
        <strong className="uppercase">
            {alert.type}!
        </strong>
        {alert.text}
        <button type="button" className="la la-times" onClick={hide} />
    </div>
}

export default Alert