import React from "react"
import styles from "./Textarea.module.scss"

const Textarea = props => {
    const inputId = `input_${Math.random()}`
    const cls = [styles.Textarea]
    if (props.hasError)
        cls.push(styles.error)


    return <div className={cls.join(" ")}>
        <label className="label block mb-2" htmlFor={inputId}>
            {props.label}
        </label>
        <textarea
            className={styles.Textarea}
            placeholder={props.placeholder}
            onChange={props.changeHandler}
            value={props.value}
        />
        {props.hasError ? <small className="block mt-2">{props.errorMessage}</small> : null}
    </div>
}

export default Textarea