import React, {useContext, useEffect} from "react"
import styles from "./Statistics.module.scss"
import {ServiceContext} from "../../context/service/serviceContext"
import CustomCard from "../../hoc/Card/CustomCard"
import KeyValueRow from "../../components/Ui/KeyValueRow/KeyValueRow"
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Pie } from 'react-chartjs-2'

const Statistics = props => {
    const {getAllStatistics, state} = useContext(ServiceContext)

    useEffect(() => {
        getAllStatistics()
        // eslint-disable-next-line
    }, [])

    ChartJS.register(ArcElement, Tooltip, Legend);

    const colorScheme = [
        "#25CCF7","#FD7272","#54a0ff","#00d2d3",
        "#1abc9c","#2ecc71","#3498db","#9b59b6","#34495e",
        "#16a085","#27ae60","#2980b9","#8e44ad","#2c3e50",
        "#f1c40f","#e67e22","#e74c3c","#ecf0f1","#95a5a6",
        "#f39c12","#d35400","#c0392b","#bdc3c7","#7f8c8d",
        "#55efc4","#81ecec","#74b9ff","#a29bfe","#dfe6e9",
        "#00b894","#00cec9","#0984e3","#6c5ce7","#ffeaa7",
        "#fab1a0","#ff7675","#fd79a8","#fdcb6e","#e17055",
        "#d63031","#feca57","#5f27cd","#54a0ff","#01a3a4"
    ]

    if (state.statistics.totals.length === 0)
        return null

    const byCities = []
    const byTypeName = []

    state.statistics.totals.forEach(el => {
        const cityRec = byCities.find(x => x.city === el.city)
        if (!cityRec)
        {
            byCities.push({
                city: el.city,
                items: [el]
            })
            return
        }

        if (cityRec.items.find(x => x.typeId === el.typeId))
            return

        cityRec.items.push(el)
    })

    state.statistics.totals.forEach(el => {
        const typeRec = byTypeName.find(x => x.typeName === el.typeName)
        if (!typeRec)
        {
            byTypeName.push({
                typeName: el.typeName,
                items: [el]
            })
            return
        }

        if (typeRec.items.find(x => x.city === el.city))
            return

        typeRec.items.push(el)
    })

    const dataByCities = {
        labels: byCities.map(el => el.city),
        datasets: [{
            label: 'By cities',
            data: byCities.map(el => {
                let count = 0
                el.items.forEach(i => {
                    count += i.requestCount
                })
                return count
            }),
            backgroundColor: colorScheme,
            borderColor: colorScheme,
            borderWidth: 1,
        }],
    }
    const dataTypeName = {
        labels: byTypeName.map(el => el.typeName),
        datasets: [{
            label: 'By types',
            data: byTypeName.map(el => {
                let count = 0
                el.items.forEach(i => {
                    count += i.requestCount
                })
                return count
            }),
            backgroundColor: colorScheme,
            borderColor: colorScheme,
            borderWidth: 1,
        }],
    }

    return <div className={styles.Statistics}>
        <h1>Статистика по містам</h1>
        <div className={styles.grid}>
            <CustomCard title="Графік">
                <Pie data={dataByCities} />
            </CustomCard>
            <div className={styles.maxH700}>
                {byCities.map((el) => <CustomCard key={el.city} title={el.city + (!el.city ? '' : ' - ' + el.items.map(i => i.requestCount).reduce((prev, cur) => prev + cur, 0))}>
                    {el.items.map(i => <KeyValueRow key={i.typeId} dataKey={i.typeName} dataValue={i.requestCount} />)}
                </CustomCard>)}
            </div>
        </div>
        <h1>Статистика по типам</h1>
        <div className={styles.grid}>
            <CustomCard title="Графік">
                <Pie data={dataTypeName} />
            </CustomCard>
            <div className={styles.maxH700}>
                {byTypeName.map((el) => <CustomCard key={el.typeName} title={el.typeName + ' - ' + el.items.map(i => i.requestCount).reduce((prev, cur) => prev + cur, 0)}>
                    {el.items.map(i => <KeyValueRow key={i.city} dataKey={i.city} dataValue={i.requestCount} />)}
                </CustomCard>)}
            </div>
        </div>
    </div>
}

export default Statistics