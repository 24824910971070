import React, {useContext, useReducer} from "react"
import {ServiceContext} from "./serviceContext"
import {serviceReducer} from "./serviceReducer"
import {AlertContext} from "../alert/alertContext"
import {
    ACTIVATION_ACTIVATE_USER,
    ACTIVATION_CLEAR_ACTIVATION,
    ACTIVATION_CLEAR_LOADING,
    ACTIVATION_SEARCH_USER,
    ACTIVATION_SET_FORM,
    ACTIVATION_SET_LOADING,
    AUTH_LOGIN,
    AUTH_LOGOUT,
    BDCOM_REQUEST_SET_FORM,
    BDCOM_SEARCH_SET_FORM,
    CHANGE_ONU_CREATE_ZTE_FORM,
    DEVICE_GROUP_CREATE,
    DEVICE_GROUP_DELETE,
    DEVICE_GROUP_GET_ALL,
    DEVICE_GROUP_SET_FORM,
    DEVICE_GROUP_UPDATE,
    JUNIPER_REQUEST_SET_FORM,
    ONU_ADD_BDCOM_ONU_VLAN,
    ONU_CLEAR_BDCOM_ALL_ONU,
    DEVICE_GET_ALL,
    ONU_GET_BDCOM_LIST,
    ONU_GET_BDCOM_ONU_SIGNAL,
    ONU_GET_CREATE_ZTE_FORM,
    ONU_GET_JUNIPER_LIST,
    ONU_GET_ZTE_LIST,
    ONU_RELOAD_BDCOM_ONU,
    ONU_RELOAD_BDCOM_PON_PORT,
    ONU_REMOVE_BDCOM_ONU,
    ONU_SEARCH_BDCOM_ONU_BY_MAC,
    SEND_ONU_CREATE_ZTE_REQUEST,
    ZTE_REQUEST_SET_FORM,
    DEVICE_SET_FORM,
    DEVICE_CREATE,
    DEVICE_UPDATE,
    DEVICE_DELETE,
    USER_SET_CHANGE_FORM,
    USER_SET_PASSWORD_FORM,
    USER_GET_ALL_BY_ROLE,
    USER_CREATE,
    USER_UPDATE,
    USER_DELETE,
    REQUEST_GET_ALL_BY_USER_LOGIN,
    CUSTOMER_SEARCH,
    REQUEST_CREATE,
    CUSTOMER_CLEAR,
    CALL_HISTORY_GET_ALL,
    CALL_HISTORY_GET_BY_USER_LOGIN,
    CALL_HISTORY_SET_PROCESSED,
    CALL_HISTORY_SET_COMMENT,
    USER_SIDE_GET_DATA,
    USER_CHANGE_PASSWORD,
    REQUEST_GET_ALL, REQUEST_TYPES_GET_ALL, STATISTICS_GET_TOTALS
} from "../types"

const initialActivationForm = () => ({
    userAccount: '',
    mac: ''
})
const initialBdcomRequestForm = () => ({
    ip: "",
    port: ""
})
const initialBdcomSearchForm = () => ({
    ip: "",
    mac: ""
})
const initialZteRequestForm = () => ({
    ip: ""
})
const initialJuniperRequestForm = () => ({
    ip: "",
    mac: ""
})
const initialCreateDeviceGroupForm = () => ({
    id: 0,
    connectionUserName: "",
    connectionPassword: "",
    title: "",
    deviceType: ""
})
const initialCreateDeviceForm = () => ({
    id: 0,
    groupId: "",
    groupName: "",
    connectionUserName: "",
    connectionPassword: "",
    title: "",
    ip: "",
    protocolType: ""
})
const initialUserChangeForm = () => ({
    id: 0,
    login: "",
    firstName: "",
    lastName: "",
    surname: "",
    email: "",
    phone: "",
    password: "",
    balance: 0,
    isOperator: false,
    roles: []
})
const initialUserPasswordForm = () => ({
    userId: 0,
    newPassword: "",
    passwordConfirm: ""
})
const initialStatistics = () => ({
    totals: []
})

export const ServiceState = ({children}) => {
    const {show: showAlert, hide: hideAlert} = useContext(AlertContext)
    const initialState = {
        identity: null,
        loading: false,
        activationForm: initialActivationForm(),
        bdcomRequestForm: initialBdcomRequestForm(),
        bdcomSearchForm: initialBdcomSearchForm(),
        zteRequestForm: initialZteRequestForm(),
        juniperRequestForm: initialJuniperRequestForm(),
        searchBdcomOnuByMacResult: "",
        user: {},
        devices: [],
        bdcomOnluList: [],
        zteOnuList: [],
        zteCreateOnuForm: null,
        juniperOnuList: [],
        createDeviceGroupForm: initialCreateDeviceGroupForm(),
        deviceGroupList: [],
        createDeviceForm: initialCreateDeviceForm(),
        userChangeForm: initialUserChangeForm(),
        userPasswordForm: initialUserPasswordForm(),
        adminUserList: [],
        dealerUserList: [],
        requestTypes: [],
        requestList: [],
        statistics: initialStatistics(),
        callHistory: [],
        customer: null,
        customerCallHistory: [],
        customerUserSide: [],
        isActivationSuccess: false
    }

    const [state, dispatch] = useReducer(serviceReducer, initialState)

    // AUTH

    const authLogin = (login, password, rememberMe = false) => {
        setLoading()

        const data = {login, password, rememberMe}

        makeRequest('Auth', 'Login', 'POST', data)
            .then(data => {
                if (!data.isSuccess)
                    return

                makeLogin(data.data)
            })
        .catch(() => {
            showAlert("Помиока сервера", 'error')
            clearLoading()
        });
    }

    const makeLogin = (data, expirationDate = null) => {
        if (!data.roles.includes("Admin"))
        {
            showAlert("Access denied", 'error')

            dispatch({
                type: AUTH_LOGOUT
            })
        }

        const expiresIn = !!expirationDate ? expirationDate : new Date(new Date().getTime() + data.expiresIn * 1000)
        localStorage.setItem("identity", JSON.stringify(data))
        localStorage.setItem("expiresIn", expiresIn)

        window.autoLogout = setTimeout(authLogout, (expiresIn.getTime() - new Date().getTime()))

        dispatch({
            type: AUTH_LOGIN,
            payload: data
        })
    }

    const autoLogin = () => {
        const identity = JSON.parse(localStorage.getItem("identity"))
        const expiresIn = new Date(localStorage.getItem("expiresIn"))

        if (expiresIn <= new Date())
            authLogout()
        else
            makeLogin(identity, expiresIn)
    }

    const authLogout = () => {
        localStorage.removeItem("identity")
        localStorage.removeItem("expiresIn")

        clearTimeout(window.autoLogout)
        window.autoLogout = null

        dispatch({
            type: AUTH_LOGOUT
        })
    }

    const isAuthenticated = () => state.identity !== null

    const isUserOperator = () => state.identity.roles.includes("Admin")

    // END AUTH

    // ONU BDCOM

    const setBdcomRequestForm = form => dispatch({
        type: BDCOM_REQUEST_SET_FORM,
        payload: form
    })

    const setBdcomSearchForm = form => dispatch({
        type: BDCOM_SEARCH_SET_FORM,
        payload: form
    })

    const getBdcomOnuList = () => {
        setLoading()

        makeRequest('onu/Bdcom', `GetOnuList?Ip=${state.bdcomRequestForm.ip}&Port=${state.bdcomRequestForm.port}`)
        .then(data => {
            if (!data.isSuccess)
                return

            dispatch({
                type: ONU_GET_BDCOM_LIST,
                payload: data.data
            })
        })
        .catch(() => {
            showAlert("Помиока сервера", 'error')
            clearLoading()
        });
    }

    const searchBdcomOnuByMac = () => {
        setLoading()

        makeRequest('onu/Bdcom', `SearchOnuByMac`, 'POST', {ip: state.bdcomSearchForm.ip, mac: state.bdcomSearchForm.mac})
        .then(data => {
            if (!data.isSuccess)
                return
            dispatch({
                type: ONU_SEARCH_BDCOM_ONU_BY_MAC,
                payload: data.data.data
            })
        })
        .catch(() => {
            showAlert("Помиока сервера", 'error')
            clearLoading()
        });
    }

    const getBdcomOnuSignal = (id, mac) => {
        setLoading()

        const params = {
            ip: state.bdcomRequestForm.ip,
            port: state.bdcomRequestForm.port,
            id: id,
            mac: mac
        }

        makeRequest('onu/Bdcom', `GetOnuSignal`, 'POST', params)
        .then(data => {
            if (!data.isSuccess)
                return

            const list = state.bdcomOnluList.map(el => {
                if (el.id === id && el.mac === mac)
                    el['signal'] = data.data.data.includes("interface") ? "No Signal" : data.data.data

                return el
            })
            dispatch({
                type: ONU_GET_BDCOM_ONU_SIGNAL,
                payload: list
            })
        })
        .catch(() => {
            showAlert("Помиока сервера", 'error')
            clearLoading()
        });
    }

    const removeBdcomOnu = (id, mac) => {
        setLoading()

        const params = {
            ip: state.bdcomRequestForm.ip,
            port: state.bdcomRequestForm.port,
            id: id,
            mac: mac
        }

        makeRequest('onu/Bdcom', `RemoveOnu`, 'POST', params)
            .then(data => {
                if (!data.isSuccess)
                    return

                showAlert("Onu Видалено", 'success')
                dispatch({
                    type: ONU_REMOVE_BDCOM_ONU,
                    payload: null
                })
            })
            .catch(() => {
                showAlert("Помиока сервера", 'error')
                clearLoading()
            });
    }

    const addBdcomOnuVlan = (id, mac, vlan) => {
        setLoading()

        const params = {
            ip: state.bdcomRequestForm.ip,
            port: state.bdcomRequestForm.port,
            id: id,
            mac: mac,
            vlan: vlan
        }

        makeRequest('onu/Bdcom', `AddVlanToOnu`, 'POST', params)
            .then(data => {
                if (!data.isSuccess)
                    return

                showAlert("Vlan Додано", 'success')
                dispatch({
                    type: ONU_ADD_BDCOM_ONU_VLAN,
                    payload: null
                })
            })
            .catch(() => {
                showAlert("Помиока сервера", 'error')
                clearLoading()
            });
    }

    const reloadBdcomOnu = (id, mac) => {
        setLoading()

        const params = {
            ip: state.bdcomRequestForm.ip,
            port: state.bdcomRequestForm.port,
            id: id,
            mac: mac
        }

        makeRequest('onu/Bdcom', `ReloadOnu`, 'POST', params)
            .then(data => {
                if (!data.isSuccess)
                    return

                showAlert("Onu Перезавантажено", 'success')
                dispatch({
                    type: ONU_RELOAD_BDCOM_ONU,
                    payload: null
                })
            })
            .catch(() => {
                showAlert("Помиока сервера", 'error')
                clearLoading()
            });
    }

    const reloadBdcomPonPort = () => {
        setLoading()

        const params = {
            ip: state.bdcomRequestForm.ip,
            port: state.bdcomRequestForm.port
        }

        makeRequest('onu/Bdcom', `ReloadPonPort`, 'POST', params)
            .then(data => {
                if (!data.isSuccess)
                    return

                showAlert("PON Port Перезавантажено", 'success')
                dispatch({
                    type: ONU_RELOAD_BDCOM_PON_PORT,
                    payload: null
                })
            })
            .catch(() => {
                showAlert("Помиока сервера", 'error')
                clearLoading()
            });
    }

    const clearBdcomAllOnu = () => {
        setLoading()

        const params = {
            ip: state.bdcomRequestForm.ip,
            port: state.bdcomRequestForm.port
        }

        makeRequest('onu/Bdcom', `ClearAllOnu`, 'POST', params)
            .then(data => {
                if (!data.isSuccess)
                    return

                showAlert("PON Port Очищено", 'success')
                dispatch({
                    type: ONU_CLEAR_BDCOM_ALL_ONU,
                    payload: null
                })
            })
            .catch(() => {
                showAlert("Помиока сервера", 'error')
                clearLoading()
            });
    }

    // END ONU BDCOM

    // ONU ZTE

    const setZteRequestForm = form => dispatch({
        type: ZTE_REQUEST_SET_FORM,
        payload: form
    })

    const getZteOnuList = () => {
        setLoading()

        makeRequest('onu/Zte', `GetOnuList?Ip=${state.zteRequestForm.ip}`)
            .then(data => {
                if (!data.isSuccess)
                    return

                dispatch({
                    type: ONU_GET_ZTE_LIST,
                    payload: data.data
                })
            })
            .catch(() => {
                showAlert("Помиока сервера", 'error')
                dispatch({
                    type: ONU_GET_ZTE_LIST,
                    payload: []
                })
            });
    }

    const getCreateZteOnuForm = (no, sn) => {
        setLoading()

        makeRequest('onu/Zte', `GetCreateOnuForm?Ip=${state.zteRequestForm.ip}&Sn=${sn}&No=${no}`)
            .then(data => {
                if (!data.isSuccess)
                    return

                const form = {
                    ip: state.zteRequestForm.ip,
                    connectionType: data.data.connectionTypes,
                    portNo: data.data.portNo,
                    sn: data.data.sn,
                    onuNo: 0,
                    vlan: 0,
                    isRemoteId: false,
                    availableOnuNo: data.data.onuNo
                }

                dispatch({
                    type: ONU_GET_CREATE_ZTE_FORM,
                    payload: form
                })
            })
            .catch(() => {
                showAlert("Помиока сервера", 'error')
                clearLoading()
            });
    }

    const setCreateZteOnuForm = form => dispatch({
        type: CHANGE_ONU_CREATE_ZTE_FORM,
        payload: form
    })

    const sendCreateZteOnuRequest = () => {
        setLoading()

        const params = {
            ip: state.zteCreateOnuForm.ip,
            connectionType: state.zteCreateOnuForm.connectionType,
            portNo: state.zteCreateOnuForm.portNo,
            onuNo: state.zteCreateOnuForm.onuNo,
            vlan: state.zteCreateOnuForm.vlan,
            sn: state.zteCreateOnuForm.sn,
            isRemoteId: state.zteCreateOnuForm.isRemoteId
        }

        makeRequest('onu/Zte', `CreateOnu`, 'POST', params)
            .then(data => {
                if (!data.isSuccess)
                    return

                showAlert("Onu створено", 'success')
                dispatch({
                    type: SEND_ONU_CREATE_ZTE_REQUEST
                })
            })
            .catch(() => {
                showAlert("Помиока сервера", 'error')
                clearLoading()
            })
    }

    // END ONU ZTE

    // ONU JUNIPER

    const setJuniperRequestForm = form => dispatch({
        type: JUNIPER_REQUEST_SET_FORM,
        payload: form
    })

    const getJuniperOnuList = () => {
        setLoading()

        makeRequest('onu/Juniper', `CheckHasActiveSessions?Ip=${state.juniperRequestForm.ip}&Mac=${state.juniperRequestForm.mac}`)
            .then(data => {
                if (!data.isSuccess)
                    return

                dispatch({
                    type: ONU_GET_JUNIPER_LIST,
                    payload: data.data
                })
            })
            .catch(() => {
                showAlert("Помиока сервера", 'error')
                clearLoading()
            });
    }

    const clearJuniperSession = () => {
        setLoading()

        const params = {
            Ip: state.juniperRequestForm.ip,
            Mac: state.juniperRequestForm.mac
        }

        makeRequest('onu/Juniper', 'ClearSessions', 'POST', params)
            .then(data => {
                if (!data.isSuccess)
                    return

                getJuniperOnuList()
            })
            .catch(() => {
                showAlert("Помиока сервера", 'error')
                clearLoading()
            });
    }

    // END ONU JUNIPER

    // ACTIVATION

    const searchUser = () => {
        setLoading()

        makeRequest('Activation', `Search?userName=${state.activationForm.userAccount}&mac=${state.activationForm.mac}`)
            .then(data => {
                if (!data.isSuccess)
                    return

                dispatch({
                    type: ACTIVATION_SEARCH_USER,
                    payload: data.data
                })
            })
            .catch(() => {
                showAlert("Помиока сервера", 'error')
                clearLoading()
            });
    }

    const activateUser = async () => {
        setLoading()
        // ...

        dispatch({
            type: ACTIVATION_ACTIVATE_USER,
            payload: {}
        })
    }

    const clearActivation = () => dispatch({
        type: ACTIVATION_CLEAR_ACTIVATION,
        payload: {
            // activationForm: initialActivationForm(),
            user: {},
            isActivationSuccess: false
        }
    })

    const setActivationForm = form => dispatch({
        type: ACTIVATION_SET_FORM,
        payload: form
    })

    // END ACTIVATION

    // DEVICE GROUPS

    const setCreateFromDeviceGroup = form => dispatch({
        type: DEVICE_GROUP_SET_FORM,
        payload: form
    })

    const clearCreateFromDeviceGroup = () => dispatch({
        type: DEVICE_GROUP_UPDATE,
        payload: initialCreateDeviceGroupForm()
    })

    const getAllDeviceGroup = () => {
        setLoading()

        makeRequest('DeviceGroup', 'GetAll')
            .then(data => {
                if (!data.isSuccess)
                    return

                dispatch({
                    type: DEVICE_GROUP_GET_ALL,
                    payload: data.data
                })
            })
            .catch(() => {
                showAlert("Помиока сервера", 'error')
                clearLoading()
            });
    }

    const createDeviceGroup = () => {
        setLoading()

        makeRequest('DeviceGroup', 'Create', 'POST', state.createDeviceGroupForm)
            .then(data => {
                if (!data.isSuccess)
                    return

                dispatch({
                    type: DEVICE_GROUP_CREATE,
                    payload: initialCreateDeviceGroupForm()
                })
                getAllDeviceGroup()
                showAlert("Device Group створено", 'success')
            })
            .catch(() => {
                showAlert("Помиока сервера", 'error')
                clearLoading()
            });
    }

    const updateDeviceGroup = () => {
        setLoading()

        makeRequest('DeviceGroup', 'Update', 'PUT', state.createDeviceGroupForm)
            .then(data => {
                if (!data.isSuccess)
                    return

                dispatch({
                    type: DEVICE_GROUP_UPDATE,
                    payload: initialCreateDeviceGroupForm()
                })
                getAllDeviceGroup()
                showAlert("Device Group оновлено", 'success')
            })
            .catch(() => {
                showAlert("Помиока сервера", 'error')
                clearLoading()
            });
    }

    const deleteDeviceGroup = (id) => {
        setLoading()

        makeRequest('DeviceGroup', `Delete/?id=${id}`, 'DELETE')
            .then(data => {
                if (!data.isSuccess)
                    return

                dispatch({
                    type: DEVICE_GROUP_DELETE
                })
                getAllDeviceGroup()
                showAlert("Device Group видалено", 'success')
            })
            .catch(() => {
                showAlert("Помиока сервера", 'error')
                clearLoading()
            });
    }

    // END DEVICE GROUPS

    // DEVICES

    const setCreateFromDevice = form => dispatch({
        type: DEVICE_SET_FORM,
        payload: form
    })

    const clearCreateFromDevice = () => dispatch({
        type: DEVICE_SET_FORM,
        payload: initialCreateDeviceForm()
    })

    const getAllDevices = () => {
        setLoading()

        makeRequest('Device', 'GetAll')
            .then(data => {
                if (data.isSuccess)
                    dispatch({
                        type: DEVICE_GET_ALL,
                        payload: data.data
                    })
            })
            .catch(() => {
                showAlert("Помиока сервера", 'error')
                clearLoading()
            });
    }

    const createDevice = () => {
        setLoading()

        makeRequest('Device', 'Create', 'POST', state.createDeviceForm)
            .then(data => {
                if (!data.isSuccess)
                    return

                dispatch({
                    type: DEVICE_CREATE,
                    payload: initialCreateDeviceForm()
                })
                getAllDevices()
                showAlert("Device створено", 'success')
            })
            .catch(() => {
                showAlert("Помиока сервера", 'error')
                clearLoading()
            });
    }

    const updateDevice = () => {
        setLoading()

        makeRequest('Device', 'Update', 'PUT', state.createDeviceForm)
            .then(data => {
                if (!data.isSuccess)
                    return

                dispatch({
                    type: DEVICE_UPDATE,
                    payload: initialCreateDeviceForm()
                })
                getAllDevices()
                showAlert("Device оновлено", 'success')
            })
            .catch(() => {
                showAlert("Помиока сервера", 'error')
                clearLoading()
            });
    }

    const deleteDevice = (id) => {
        setLoading()

        makeRequest('Device', `Delete/?id=${id}`, 'DELETE')
            .then(data => {
                if (!data.isSuccess)
                    return

                dispatch({
                    type: DEVICE_DELETE
                })
                getAllDevices()
                showAlert("Device видалено", 'success')
            })
            .catch(() => {
                showAlert("Помиока сервера", 'error')
                clearLoading()
            });
    }

    // END DEVICES

    // USERS

    // export const USER_UPDATE_BALANCE = "USER_UPDATE_BALANCE"
    // export const USER_CHANGE_PASSWORD = "USER_CHANGE_PASSWORD"

    const setChangeUserForm = form => dispatch({
        type: USER_SET_CHANGE_FORM,
        payload: form
    })

    const setSetUserPasswordForm = form => dispatch({
        type: USER_SET_PASSWORD_FORM,
        payload: form
    })

    const clearChangeUserForm = () => setChangeUserForm(initialUserChangeForm())

    const clearUserPasswordForm = () => setSetUserPasswordForm(initialUserPasswordForm())

    const getAllUsers = () => {
        setLoading()

        Promise.all([
            makeRequest('User', 'GetAllByRole?role=Admin'),
            makeRequest('User', 'GetAllByRole?role=Dealer')
        ])
        .then(users => {
            if (!users[0].isSuccess || !users[1].isSuccess)
                return

            dispatch({
                type: USER_GET_ALL_BY_ROLE,
                payload: {
                    adminList: users[0].data,
                    dealerList: users[1].data
                }
            })
        })
        .catch(() => {
            showAlert("Помиока сервера", 'error')
            clearLoading()
        });
    }

    const createUser = (role) => {
        setLoading()

        state.userChangeForm.roles.push(role)
        makeRequest('User', 'Create', 'POST', state.userChangeForm)
            .then(data => {
                if (!data.isSuccess)
                    return

                dispatch({
                    type: USER_CREATE,
                    payload: initialUserChangeForm()
                })
                getAllUsers()
                showAlert("User створено", 'success')
            })
            .catch(() => {
                showAlert("Помиока сервера", 'error')
                clearLoading()
            });
    }

    const updateUser = () => {
        setLoading()

        makeRequest('User', 'Update', 'PUT', state.userChangeForm)
            .then(data => {
                if (!data.isSuccess)
                    return

                dispatch({
                    type: USER_UPDATE,
                    payload: initialUserChangeForm()
                })
                getAllUsers()
                showAlert("User оновлено", 'success')
            })
            .catch(() => {
                showAlert("Помиока сервера", 'error')
                clearLoading()
            });
    }

    const deleteUser = (id) => {
        setLoading()

        makeRequest('User', `Delete/?id=${id}`, 'DELETE')
            .then(data => {
                if (!data.isSuccess)
                    return

                dispatch({
                    type: USER_DELETE
                })
                getAllUsers()
                showAlert("User видалено", 'success')
            })
            .catch(() => {
                showAlert("Помиока сервера", 'error')
                clearLoading()
            });
    }

    const userChangePassword = (form) => {
        setLoading()

        makeRequest('User', 'ChangePassword', 'PATCH', form)
            .then(data => {
                if (!data.isSuccess)
                    return

                dispatch({
                    type: USER_CHANGE_PASSWORD
                })

                showAlert("Пароль змінено", 'success')
            })
            .catch(() => {
                showAlert("Помиока сервера", 'error')
                clearLoading()
            });
    }

    // END USERS

    // REQUESTS

    const getAllRequestTypes = () => {
        setLoading()

        makeRequest('RequestType', 'GetAll')
            .then(data => {
                if (!data.isSuccess)
                    return

                dispatch({
                    type: REQUEST_TYPES_GET_ALL,
                    payload: data.data
                })
            })
            .catch(() => {
                showAlert("Помиока сервера", 'error')
                clearLoading()
            });
    }

    const getAllRequest = () => {
        setLoading()

        makeRequest('Request', 'GetAll')
            .then(data => {
                if (!data.isSuccess)
                    return

                dispatch({
                    type: REQUEST_GET_ALL,
                    payload: data.data
                })
            })
            .catch(() => {
                showAlert("Помиока сервера", 'error')
                clearLoading()
            });
    }

    const getAllRequestByLogin = (login) => {
        setLoading()

        makeRequest('Request', `GetAllByUserLogin?userLogin=${login}`)
            .then(data => {
                if (!data.isSuccess)
                    return

                dispatch({
                    type: REQUEST_GET_ALL_BY_USER_LOGIN,
                    payload: data.data
                })
            })
            .catch(() => {
                showAlert("Помиока сервера", 'error')
                clearLoading()
            });
    }

    const createRequest = (form) => {
        setLoading()

        let params = {
            typeId: form.type,
            vlan: form.vlan,
            comment: form.comment,
            userPhone: form.phone,
            userIp: '',
            maCs: [],
        }

        if (form.hasOwnProperty('userFio'))
        {
            params.userLogin = form.userLogin
            params.userFio = form.userFio
            params.userIp = form.userIp
            params.city = form.city
            params.street = form.street
            params.house = form.house
            params.room = form.room
        }

        if (state.customer)
            params = {
                ...params,
                userId: state.customer.id,
                userLogin: state.customer.userLogin,
                userFio: state.customer.userFio,
                group: state.customer.group,
                balance: state.customer.balance,
                city: state.customer.city,
                street: state.customer.street,
                house: state.customer.house,
                room: state.customer.room,
            }

        makeRequest('Request', `Create`, 'POST', params)
            .then(data => {
                if (!data.isSuccess)
                    return

                dispatch({
                    type: REQUEST_CREATE,
                    payload: data.data
                })

                if (state.customer != null)
                    getAllRequestByLogin(state.customer.userLogin)
                showAlert("Звернення зареєстровано", 'success')
            })
            .catch(() => {
                showAlert("Помиока сервера", 'error')
                clearLoading()
            });
    }

    const clearAllRequests = () => dispatch({
        type: REQUEST_GET_ALL,
        payload: []
    })

    // END REQUESTS

    // CUSTOMER

    const searchCustomer = (login) => {
        setLoading()

        makeRequest('Customer', `Search?userName=${login}`)
            .then(data => {
                if (!data.isSuccess)
                    return

                dispatch({
                    type: CUSTOMER_SEARCH,
                    payload: data.data
                })

                getAllRequestByLogin(data.data.userLogin)
                callHistoryGetAllByLogin(data.data.userLogin)
                userSideGetData(data.data.maCs)
            })
            .catch(() => {
                showAlert("Помиока сервера", 'error')
                clearLoading()
            });
    }

    const clearCustomer = () => dispatch({type: CUSTOMER_CLEAR})

    // END CUSTOMER

    // CALL HISTORY

    const callHistoryGetAll = () => {
        setLoading()

        makeRequest('CallsHistory', `GetAll`)
            .then(data => {
                if (!data.isSuccess)
                    return

                dispatch({
                    type: CALL_HISTORY_GET_ALL,
                    payload: data.data
                })
            })
            .catch(() => {
                showAlert("Помиока сервера", 'error')
                clearLoading()
            });
    }

    const callHistoryGetAllByLogin = (login) => {
        setLoading()

        makeRequest('CallsHistory', `GetAllByUserLogin?userLogin=${login}`)
            .then(data => {
                if (!data.isSuccess)
                    return

                dispatch({
                    type: CALL_HISTORY_GET_BY_USER_LOGIN,
                    payload: data.data
                })
            })
            .catch(() => {
                showAlert("Помиока сервера", 'error')
                clearLoading()
            });
    }

    const callHistorySetProcessed = (id) => {
        setLoading()

        makeRequest('CallsHistory', `SetProcessed?id=${id}`, 'PATCH')
            .then(data => {
                if (!data.isSuccess)
                    return

                dispatch({
                    type: CALL_HISTORY_SET_PROCESSED
                })

                callHistoryGetAll()
                if(state.customer?.userLogin)
                    callHistoryGetAllByLogin(state.customer?.userLogin)

                showAlert("Оновлено", 'success')
            })
            .catch(() => {
                showAlert("Помиока сервера", 'error')
                clearLoading()
            });
    }

    const callHistorySetComment = (id, comment) => {
        setLoading()

        makeRequest('CallsHistory', `SetComment?id=${id}&comment=${comment}`, 'PATCH')
            .then(data => {
                if (!data.isSuccess)
                    return

                dispatch({
                    type: CALL_HISTORY_SET_COMMENT
                })

                callHistoryGetAll()
                if(state.customer?.userLogin)
                    callHistoryGetAllByLogin(state.customer?.userLogin)

                showAlert("Оновлено", 'success')
            })
            .catch(() => {
                showAlert("Помиока сервера", 'error')
                clearLoading()
            });
    }

    // END CALL HISTORY

    // USER SIDE

    const userSideGetData = async (macs) => {
        setLoading()

        const data = []

        for (const mac of macs) {
            let request
            request = await makeRequest('UserSide', `GetByMac?mac=${mac?.toUpperCase()}`)
            const device = !request.isSuccess ? null : request.data[0]

            if (!device)
                continue

            request = await makeRequest('Switch', `GetByCode?code=${device.devcode}`)
            const switchInfo = !request.isSuccess ? null : request.data

            let ifaceName = device.ifaceCaption
                .replace(/epon/i, "")
                .replace(/gpon/i, "")
                .replace("_", "")
                .replace(" ", "")
            let iface = device.ifaceCaption.split("/")
            if (iface.length >= 2)
                ifaceName = `${iface[iface.length - 2]}/${iface[iface.length - 1]}`

            request = await makeRequest('PonOnuData', `GetByDeviceIdAndIfaceName?deviceId=${device.devcode}&ifaceName=${ifaceName}`)
            const ponOnuData = !request.isSuccess ? null : request.data[0]

            data.push({
                location: switchInfo.location,
                name: switchInfo.nazv,
                "switch port": !device.ifaceCaption.includes("epon") && !device.ifaceCaption.includes("gpon") ? device.ifaceCaption : null,
                "portOnu": ponOnuData?.ifaceName, //device?.ifaceCaption,
                "mac/sn": !!ponOnuData?.mac ? ponOnuData?.mac : ponOnuData?.sn,
                levelOnuRx: ponOnuData?.levelOnuRx,
                vendor: ponOnuData?.vendor,
                model: ponOnuData?.model,
                firmware: ponOnuData?.firmware,
            })
        }

        dispatch({
            type: USER_SIDE_GET_DATA,
            payload: data
        })

        if (data.length === 0)
            showAlert('User Side data not found', 'error')
    }

    // END USER SIDE

    // STATISTICS
    const getAllStatistics = () => {
        setLoading()

        makeRequest('Statistics', `GetTotals`)
            .then(data => {
                if (!data.isSuccess)
                    return

                dispatch({
                    type: STATISTICS_GET_TOTALS,
                    payload: {...state.statistics, totals: data.data}
                })
            })
            .catch(() => {
                showAlert("Помиока сервера", 'error')
                clearLoading()
            });
    }

    // END STATISTICS

    const setLoading = () => {
        hideAlert()
        dispatch({type: ACTIVATION_SET_LOADING})
    }

    const clearLoading = () => dispatch({type: ACTIVATION_CLEAR_LOADING})

    const makeRequest = async (controller, action, method = 'GET', data = {}) => {
        let url = `${process.env.REACT_APP_API_ENDPOINT}${controller}`
        if (action && action.length > 0)
            url += `/${action}`

        let headers = {
            'Content-Type': 'application/json'
            // 'Content-Type': 'application/x-www-form-urlencoded',
        }

        if (state.identity !== null)
        {
            headers['Authorization'] = `Bearer ${state.identity.accessToken}`
        }

        let params = {
            cache: "no-store",
            method: method,
            headers: headers
        }

        if (method === 'POST')
            params['body'] = JSON.stringify(data)

        if (method === 'PUT')
            params['body'] = JSON.stringify(data)

        if (method === 'PATCH' && !!data)
            params['body'] = JSON.stringify(data)

        const request = await fetch(url, params)
        const result = {
            isSuccess: request.status >= 200 && request.status < 300,
            data: request.status === 204 ? null : await request.json()
        }

        if (!result.isSuccess)
        {
            let errors = "Error: "
            if (!!result.data?.title)
                errors += `${result.data?.title} `
            if (!!result.data?.data)
                errors += `${result.data?.data} `

            if (!!result.data.errors)
                Object.keys(result.data.errors).forEach(key => {
                    errors += result.data.errors[key].join(" ")
                })

            showAlert(errors, 'error')
            clearLoading()
        }

        return Promise.resolve(result)
    }

    return (<ServiceContext.Provider value={{
        searchUser, activateUser, clearActivation, setActivationForm,
        authLogin, authLogout, isAuthenticated, isUserOperator, autoLogin,
        setBdcomRequestForm, setBdcomSearchForm, getBdcomOnuList, searchBdcomOnuByMac, getBdcomOnuSignal, removeBdcomOnu, addBdcomOnuVlan, reloadBdcomOnu, reloadBdcomPonPort, clearBdcomAllOnu,
        setZteRequestForm, getZteOnuList, getCreateZteOnuForm, setCreateZteOnuForm, sendCreateZteOnuRequest,
        setJuniperRequestForm, getJuniperOnuList, clearJuniperSession,
        setCreateFromDeviceGroup, clearCreateFromDeviceGroup, getAllDeviceGroup, createDeviceGroup, updateDeviceGroup, deleteDeviceGroup,
        getAllDevices, setCreateFromDevice, clearCreateFromDevice, createDevice, updateDevice, deleteDevice,
        setChangeUserForm, setSetUserPasswordForm, clearChangeUserForm, clearUserPasswordForm, getAllUsers, createUser, updateUser, deleteUser, userChangePassword,
        getAllRequestTypes, getAllRequest, getAllRequestByLogin, searchCustomer, createRequest, clearAllRequests, clearCustomer,
        getAllStatistics,
        callHistoryGetAll, callHistoryGetAllByLogin, callHistorySetProcessed, callHistorySetComment,
        userSideGetData,
        state
    }}>
        {children}
    </ServiceContext.Provider>)
}