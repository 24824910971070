import {
    ACTIVATION_SEARCH_USER,
    ACTIVATION_ACTIVATE_USER,
    ACTIVATION_SET_LOADING,
    ACTIVATION_CLEAR_ACTIVATION,
    ACTIVATION_SET_FORM,
    ACTIVATION_CLEAR_LOADING,
    DEVICE_GET_ALL,
    AUTH_LOGIN,
    AUTH_LOGOUT,
    ONU_GET_BDCOM_LIST,
    BDCOM_REQUEST_SET_FORM,
    BDCOM_SEARCH_SET_FORM,
    ONU_SEARCH_BDCOM_ONU_BY_MAC,
    ONU_GET_BDCOM_ONU_SIGNAL,
    ONU_REMOVE_BDCOM_ONU,
    ONU_ADD_BDCOM_ONU_VLAN,
    ONU_RELOAD_BDCOM_ONU,
    ONU_RELOAD_BDCOM_PON_PORT,
    ONU_CLEAR_BDCOM_ALL_ONU,
    ZTE_REQUEST_SET_FORM,
    ONU_GET_ZTE_LIST,
    JUNIPER_REQUEST_SET_FORM,
    ONU_GET_JUNIPER_LIST,
    ONU_GET_CREATE_ZTE_FORM,
    CHANGE_ONU_CREATE_ZTE_FORM,
    SEND_ONU_CREATE_ZTE_REQUEST,
    DEVICE_GROUP_SET_FORM,
    DEVICE_GROUP_GET_ALL,
    DEVICE_GROUP_CREATE,
    DEVICE_GROUP_UPDATE,
    DEVICE_GROUP_DELETE,
    DEVICE_SET_FORM,
    REQUEST_SET_FORM,
    REQUEST_GET_ALL_BY_USER_LOGIN,
    REQUEST_CREATE,
    USER_SET_CHANGE_FORM,
    USER_SET_PASSWORD_FORM,
    USER_GET_ALL_BY_ROLE,
    USER_CREATE,
    USER_UPDATE,
    USER_DELETE,
    USER_UPDATE_BALANCE,
    USER_CHANGE_PASSWORD,
    CUSTOMER_SEARCH,
    CUSTOMER_CLEAR,
    CALL_HISTORY_GET_BY_USER_LOGIN,
    CALL_HISTORY_GET_ALL,
    CALL_HISTORY_SET_PROCESSED,
    CALL_HISTORY_SET_COMMENT,
    USER_SIDE_GET_DATA, REQUEST_GET_ALL, REQUEST_TYPES_GET_ALL, STATISTICS_GET_TOTALS
} from "../types"

const handlers = {
    [AUTH_LOGIN]: (state, {payload}) => ({...state, identity: payload, loading: false}),
    [AUTH_LOGOUT]: (state) => ({...state, identity: null, loading: false}),
    [ACTIVATION_SEARCH_USER]: (state, {payload}) => ({...state, user: payload, loading: false}),
    [ACTIVATION_ACTIVATE_USER]: (state, {payload}) => ({...state, isActivationSuccess: payload, loading: false}),
    [ACTIVATION_SET_FORM]: (state, {payload}) => ({...state, activationForm: payload}),
    [ACTIVATION_CLEAR_ACTIVATION]: (state, {payload}) => ({...state, ...payload}),
    [ACTIVATION_SET_LOADING]: state => ({...state, loading: true}),
    [ACTIVATION_CLEAR_LOADING]: state => ({...state, loading: false}),

    [ONU_GET_BDCOM_LIST]: (state, {payload}) => ({...state, bdcomOnluList: payload, loading: false}),
    [ONU_SEARCH_BDCOM_ONU_BY_MAC]: (state, {payload}) => ({...state, searchBdcomOnuByMacResult: payload, loading: false}),
    [ONU_GET_BDCOM_ONU_SIGNAL]: (state, {payload}) => ({...state, bdcomOnluList: payload, loading: false}),
    [BDCOM_REQUEST_SET_FORM]: (state, {payload}) => ({...state, bdcomRequestForm: payload}),
    [BDCOM_SEARCH_SET_FORM]: (state, {payload}) => ({...state, bdcomSearchForm: payload}),
    [ONU_REMOVE_BDCOM_ONU]: (state, {payload}) => ({...state,  loading: false}),
    [ONU_ADD_BDCOM_ONU_VLAN]: (state, {payload}) => ({...state,  loading: false}),
    [ONU_RELOAD_BDCOM_ONU]: (state, {payload}) => ({...state,  loading: false}),
    [ONU_RELOAD_BDCOM_PON_PORT]: (state, {payload}) => ({...state,  loading: false}),
    [ONU_CLEAR_BDCOM_ALL_ONU]: (state, {payload}) => ({...state,  loading: false}),

    [ZTE_REQUEST_SET_FORM]: (state, {payload}) => ({...state, zteRequestForm: payload, loading: false}),
    [ONU_GET_ZTE_LIST]: (state, {payload}) => ({...state, zteOnuList: payload, zteCreateOnuForm: null, loading: false}),
    [ONU_GET_CREATE_ZTE_FORM]: (state, {payload}) => ({...state, zteCreateOnuForm: payload, loading: false}),
    [CHANGE_ONU_CREATE_ZTE_FORM]: (state, {payload}) => ({...state, zteCreateOnuForm: payload, loading: false}),
    [SEND_ONU_CREATE_ZTE_REQUEST]: (state) => ({...state, zteOnuList: [], zteCreateOnuForm: null, loading: false}),

    [JUNIPER_REQUEST_SET_FORM]: (state, {payload}) => ({...state, juniperRequestForm: payload, loading: false}),
    [ONU_GET_JUNIPER_LIST]: (state, {payload}) => ({...state, juniperOnuList: payload, loading: false}),

    [DEVICE_GROUP_SET_FORM]: (state, {payload}) => ({...state, createDeviceGroupForm: payload, loading: false}),
    [DEVICE_GROUP_GET_ALL]: (state, {payload}) => ({...state, deviceGroupList: payload, loading: false}),
    [DEVICE_GROUP_CREATE]: (state, {payload}) => ({...state, createDeviceGroupForm: payload, loading: false}),
    [DEVICE_GROUP_UPDATE]: (state, {payload}) => ({...state, createDeviceGroupForm: payload, loading: false}),
    [DEVICE_GROUP_DELETE]: (state) => ({...state, loading: false}),

    [DEVICE_GET_ALL]: (state, {payload}) => ({...state, devices: payload, loading: false}),
    [DEVICE_SET_FORM]: (state, {payload}) => ({...state, createDeviceForm: payload, loading: false}),

    [REQUEST_TYPES_GET_ALL]: (state, {payload}) => ({...state, requestTypes: payload, loading: false}),

    [REQUEST_SET_FORM]: (state, {payload}) => ({...state, createRequestForm: payload, loading: false}),
    [REQUEST_GET_ALL]: (state, {payload}) => ({...state, requestList: payload, loading: false}),
    [REQUEST_GET_ALL_BY_USER_LOGIN]: (state, {payload}) => ({...state, requestList: payload, loading: false}),
    [REQUEST_CREATE]: (state, {payload}) => ({...state, createRequestForm: payload, loading: false}),

    [STATISTICS_GET_TOTALS]: (state, {payload}) => ({...state, statistics: payload, loading: false}),

    [USER_SET_CHANGE_FORM]: (state, {payload}) => ({...state, userChangeForm: payload, loading: false}),
    [USER_SET_PASSWORD_FORM]: (state, {payload}) => ({...state, userPasswordForm: payload, loading: false}),
    [USER_GET_ALL_BY_ROLE]: (state, {payload}) => ({...state, adminUserList: payload.adminList, dealerUserList: payload.dealerList, loading: false}),
    [USER_CREATE]: (state, {payload}) => ({...state, userChangeForm: payload, loading: false}),
    [USER_UPDATE]: (state, {payload}) => ({...state, userChangeForm: payload, loading: false}),
    [USER_DELETE]: (state) => ({...state, loading: false}),
    [USER_UPDATE_BALANCE]: (state) => ({...state, loading: false}),
    [USER_CHANGE_PASSWORD]: (state) => ({...state, loading: false}),

    [CUSTOMER_SEARCH]: (state, {payload}) => ({...state, customer: payload, loading: false}),
    [CUSTOMER_CLEAR]: (state) => ({...state, customer: null, requestList: [], customerCallHistory: [], customerUserSide: [], loading: false}),

    [CALL_HISTORY_GET_ALL]: (state, {payload}) => ({...state, callHistory: payload, loading: false}),
    [CALL_HISTORY_GET_BY_USER_LOGIN]: (state, {payload}) => ({...state, customerCallHistory: payload, loading: false}),
    [CALL_HISTORY_SET_PROCESSED]: (state) => ({...state, loading: false}),
    [CALL_HISTORY_SET_COMMENT]: (state) => ({...state, loading: false}),

    [USER_SIDE_GET_DATA]: (state, {payload}) => ({...state, customerUserSide: payload, loading: false}),

    DEFAULT: state => state
}

export const serviceReducer = (state, action) => {
    const handler = handlers[action.type] || handlers.DEFAULT
    return handler(state, action)
}