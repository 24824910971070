import React from "react"
import styles from "./PrintRequest.module.scss"
import logo from "./logo.jpg"

const PrintRequestNewConnection = ({request}) => {
    if (!request)
        return null

    const monthNames = ["січня", "лютого", "березня", "квітня", "травня", "червня",
        "липня", "серпня", "вересня", "жовтня", "листопада", "грудня"
    ];
    const current = new Date()
    const created = new Date(request.createdAt)

    return <div className={[styles.PrintRequest, styles.PrintRequestNew].join(" ")}>
        <img className={[styles.logo].join(" ")} src={logo} alt="logo" />
        <div className={styles.header}>
            <span>Затверджено оператор</span>
            <h4>ТзОВ «Лінк Телеком Сервіс»</h4>
            <span>підпис ________________</span>
        </div>
        <h4 className={styles.mt100}>Заява</h4>
        <h4>про приєднання №____________</h4>
        <div className={styles.row}>
            <span>Львівська обл., м. Миколаїв </span>
            <span>{current.getDate()} {monthNames[current.getMonth()]} {current.getFullYear()}р.</span>
        </div>
        <div className={styles.center}>
            ДО ПУБЛІЧНОГО ДОГОВОРУ (ОФЕРТИ) ПРО НАДАННЯ ТЕЛЕКОМУНІКАЦІЙНИХ ПОСЛУГ
        </div>
        <div className={styles.center}>
            провайдером ФОП ________________________________
        </div>
        <div className={styles.center}>
            розміщеного на  сайті Оператора – ТзОВ «Лінк Телеком Сервіс» www.lts.org.ua (надалі - Договір)
        </div>

        <table>
            <tr>
                <th colSpan={3}>
                    Прізвище, ім’я, по батькові
                </th>
                <td colSpan={3}>
                    {request.userFio}
                </td>
            </tr>
            <tr>
                <th colSpan={3}>
                    Місце проживання/ реєстрації
                </th>
                <td colSpan={3}>
                    <div className={styles.block}>
                        &nbsp;
                        <span className={styles.line} />
                    </div>
                    <span>
                        &nbsp;
                    </span>
                </td>
            </tr>
            <tr>
                <th colSpan={3}>
                    Паспорт
                </th>
                <td colSpan={3}>
                    <span>
                        Серія______№________________
                    </span>
                    <span>
                        Виданий___________________________ від «____»______  _______ р.
                    </span>
                </td>
            </tr>
            <tr>
                <th colSpan={3}>
                    Адреса підключення/ Місце надання послуг (повна адреса)
                </th>
                <td colSpan={3}>
                    <div className={styles.block}>
                        &nbsp;
                        <span className={styles.line} />
                    </div>
                    <span>
                        &nbsp;
                    </span>
                </td>
            </tr>
            <tr>
                <th colSpan={3}>
                    Ідентифікаційний код
                </th>
                <td colSpan={3}>

                </td>
            </tr>
            <tr>
                <th>
                    Контактний телефон
                </th>
                <td width="20%">
                    {request.userPhone}
                </td>
                <th width="15%">
                    E-mail
                </th>
                <td width="20%">

                </td>
                <th width="15%">
                    Viber
                </th>
                <td width="20%">

                </td>
            </tr>
            <tr>
                <th colSpan={3}>
                    Дата підключення
                </th>
                <td colSpan={3}>

                </td>
            </tr>
            <tr>
                <th colSpan={3}>
                    Дата закінчення додаткових зобов’язань
                </th>
                <td colSpan={3}>

                </td>
            </tr>
        </table>

        <table>
            <tr>
                <th colSpan={2}>
                    Обраний тарифний план
                </th>
                <td colSpan={2}>

                </td>
            </tr>
            <tr>
                <th colSpan={2}>
                    Термін дії акції з моменту підключення
                </th>
                <td colSpan={2}>

                </td>
            </tr>
            <tr>
                <th colSpan={2}>
                    Швидкість згідно тарифного плану, до Мбіт/с
                </th>
                <td colSpan={2}>

                </td>
            </tr>
            <tr>
                <th colSpan={2}>
                    Розмір щомісячної абонентної плати  (на момент підключення)
                </th>
                <td colSpan={2}>

                </td>
            </tr>
            <tr>
                <td>Обсяги передачі інформації</td>
                <td>Без обмежень</td>
                <td>Пропускна здатність (у відсотках)</td>
                <td>не менше 90%</td>
            </tr>
            <tr>
                <td>Інтерфейс</td>
                <td>Ethernet</td>
                <td>Режим надання Послуг</td>
                <td>Цілодобово</td>
            </tr>
            <tr>
                <th colSpan={2}>
                    МАС-адреса кінцевого обладнання
                </th>
                <td colSpan={2}>

                </td>
            </tr>
            <tr>
                <th colSpan={2}>
                    МАС-адреса роутера (при наявності)
                </th>
                <td colSpan={2}>

                </td>
            </tr>
            <tr>
                <td colSpan={4}>
                    <span className={styles.center}>
                        Дані для входу в особистий кабінет:  адреса сайту  my.lts.org.ua
                    </span>
                </td>
            </tr>
            <tr>
                <th colSpan={2}>
                    Логін
                </th>
                <td colSpan={2}>

                </td>
            </tr>
            <tr>
                <th colSpan={2}>
                    Пароль
                </th>
                <td colSpan={2}>

                </td>
            </tr>
            <tr>
                <th colSpan={2}>
                    IP-адреса- видається динамічно
                </th>
                <td colSpan={2}>

                </td>
            </tr>
        </table>

        <div className={styles.block}>
            Підписуючи цю Заяву про приєднання - підтверджую, що з Публічним договором «Про надання телекомунікаційних
            послуг», Додатками до нього, чинними тарифами та умовами підключення ознайомлений(-а) і погоджуюсь.
            Відповідно до вимог Закону України «Про захист персональних даних», надаю згоду на обробку моїх персональних
            даних (прізвище, ім'я, по батькові, стать, рік, місяць, дата і місце народження, домашня адреса, адреса
            електронної пошти, номер домашнього і мобільного телефонів, відомості, які містяться в документах, що
            засвідчують особу, включаючи паспортні дані, індивідуальний податковий номер, фотокопії паспортів, інших
            особистих документів), та їх включення до комп’ютерної бази Провайдера з метою та в цілях, що зазначаються в
            Договорі, а також надаю згоду без додаткового письмового повідомлення мене на вчинення з моїми персональними
            даними таких дій: збір, обробку, а також інші дії, пов’язані з вищезазначеною метою.
        </div>

        <div className={styles.block}>
            _______________________  / _______________________  /
            підпис                              прізвище та ініціали
        </div>

        <div className={styles.block}>
            Примітки:1.	Копія Заяви надається Абоненту для використання номеру Заяви при здійсненні оплати.
        </div>


        <p style={{pageBreakBefore: 'always'}}>&nbsp;</p>

        <div className={styles.center}>
            <strong>
                АКТ ПРИЙМАННЯ-ПЕРЕДАВАННЯ РОБІТ та ПОСЛУГ
            </strong>
        </div>
        <div className={styles.center}>
            <strong>
                до ПУБЛІЧНОГО ДОГОВОРУ (ОФЕРТИ)
            </strong>
        </div>
        <div className={styles.center}>
            <strong>
                ПРО НАДАННЯ телекомунікаційних ПОСЛУГ   (далі - Договір)
            </strong>
        </div>
        <div className={styles.row}>
            <span>Львівська обл., м. Миколаїв </span>
            <span>{current.getDate()} {monthNames[current.getMonth()]} {current.getFullYear()}р.</span>
        </div>
        <div className={styles.block}>
            Цим актом до Договору , який укладено між ФОП ________________________ (надалі -
            Провайдер) і Фізичною особою __________________________ (надалі - Абонент) підтверджується факт проведення
            робіт Провайдера з підключення до мережі Інтернет.
        </div>
        <div className={styles.block}>
            <span>Використані матеріали:</span>
        </div>
        <table>
            <tr>
                <td>Кабель оптичний</td>
                <td width="25%"></td>
                <td>Натяжні</td>
                <td width="25%"></td>
            </tr>
            <tr>
                <td>Пігтейл</td>
                <td></td>
                <td>Патчкорд</td>
                <td></td>
            </tr>
            <tr>
                <td>Адаптери</td>
                <td></td>
                <td>Кабель мідний</td>
                <td></td>
            </tr>
        </table>
        <div className={styles.block}>
            <strong>
                Тестування швидкості з’єднання з мережею Інтернет при підключені ________ Мбіт/с.
            </strong>
        </div>
        <div className={styles.block}>
            Вартість підключення (Реєстрації),  ________________грн.
        </div>
        <div className={styles.block}>
            Вартість додаткових послуг,  ________________грн.
        </div>
        <div className={styles.block}>
            Роботи по підключенню до мережі Інтернет виконано належним чином та в повному обсязі, претензій Сторони одна
            до одної не мають.
        </div>
        <div className={styles.sign}>
            <div>
                <div className={styles.block}>
                    <strong>Провайдер</strong>
                </div>
                <div className={styles.block}>
                    <span className={styles.line} />
                </div>
                <div className={styles.block}>
                    <div className={styles.row}>
                        <small>М.П.</small>
                        <small>(підпис)</small>
                    </div>
                </div>
            </div>
            <div>
                <div className={styles.block}>
                    <strong>Абонент (фізична особа)</strong>
                </div>
                <div className={styles.block}>
                    <span className={styles.line} />
                </div>
                <div className={styles.block}>
                    <small>Підпис /  (прізвище та ініціали підписанта)</small>
                </div>
            </div>
        </div>

        <div className={styles.row}>
            <span className={styles.line} />
        </div>

        <div className={styles.center}>
            <strong>
                АКТ ПРИЙМАННЯ-ПЕРЕДАВАННЯ РОБІТ та ПОСЛУГ
            </strong>
        </div>
        <div className={styles.center}>
            <strong>
                до ПУБЛІЧНОГО ДОГОВОРУ (ОФЕРТИ)
            </strong>
        </div>
        <div className={styles.center}>
            <strong>
                ПРО НАДАННЯ телекомунікаційних ПОСЛУГ   (далі - Договір)
            </strong>
        </div>
        <div className={styles.row}>
            <span>Львівська обл., м. Миколаїв </span>
            <span>{current.getDate()} {monthNames[current.getMonth()]} {current.getFullYear()}р.</span>
        </div>
        <div className={styles.block}>
            Цим актом до Договору , який укладено між ФОП ________________________ (надалі -
            Провайдер) і Фізичною особою __________________________ (надалі - Абонент) підтверджується факт проведення
            робіт Провайдера з підключення до мережі Інтернет.
        </div>
        <div className={styles.block}>
            Кінцеве обладнання: Виробник ____________, Модель _______________, МАС адреса ______________________
            Фактична вартість обладнання: ____________ грн. Вартість обладнання при передачі __________ грн.
        </div>
        <div className={styles.block}>
            Wi-Fi роутер: Виробник ____________, Модель _______________, МАС адреса ______________________
            Фактична вартість обладнання: ____________ грн. Вартість обладнання при передачі __________ грн.
        </div>
        <div className={styles.block}>
            Працездатність обладнання протестована, претензій Сторони одна до одної не мають.
        </div>
        <div className={styles.sign}>
            <div>
                <div className={styles.block}>
                    <strong>Провайдер</strong>
                </div>
                <div className={styles.block}>
                    <span className={styles.line} />
                </div>
                <div className={styles.block}>
                    <div className={styles.row}>
                        <small>М.П.</small>
                        <small>(підпис)</small>
                    </div>
                </div>
            </div>
            <div>
                <div className={styles.block}>
                    <strong>Абонент (фізична особа)</strong>
                </div>
                <div className={styles.block}>
                    <span className={styles.line} />
                </div>
                <div className={styles.block}>
                    <small>Підпис /  (прізвище та ініціали підписанта)</small>
                </div>
            </div>
        </div>
    </div>
}

export default PrintRequestNewConnection