import React from "react"
import {Button, FormControl} from "@mui/material"
import TextInput from "../../Ui/TextInput/TextInput"
import Select from "../../Ui/Select/Select"
import SaveIcon from "@mui/icons-material/Save"

const DeviceGroupsFrom = props => {
    const deviceTypeList = [
        {id: 'Bdcom', title: 'Bdcom'},
        {id: 'Zte', title: 'Zte'},
        {id: 'Juniper', title: 'Juniper'}
    ]

    const inputGroupCss = {
        marginBottom: '16px'
    }

    return <FormControl variant="standard" margin="normal" fullWidth={true}>
        <div style={inputGroupCss}>
            <TextInput
                label="Title"
                name="title"
                value={props.model.title}
                onChange={e => props.onChangeForm('title' ,e)}
            />
        </div>
        <div style={inputGroupCss}>
            <Select
                options={deviceTypeList}
                label="Device Type"
                value={props.model.deviceType}
                changeHandler={e => props.onChangeForm('deviceType' ,e)}
            />
        </div>
        <div style={inputGroupCss}>
            <TextInput
                label="Connection UserName"
                name="connectionUserName"
                value={props.model.connectionUserName}
                onChange={e => props.onChangeForm('connectionUserName' ,e)}
            />
        </div>
        <div style={inputGroupCss}>
            <TextInput
                label="Connection Password"
                name="connectionPassword"
                value={props.model.connectionPassword}
                onChange={e => props.onChangeForm('connectionPassword' ,e)}
            />
        </div>
        <div>
            <Button
                variant="outlined"
                startIcon={<SaveIcon />}
                onClick={props.onClickSave}
            >
                Зберегти
            </Button>
        </div>
    </FormControl>
}

export default DeviceGroupsFrom