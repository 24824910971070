import React, {useContext} from "react"
import styles from "./Zte.module.scss"
import Card from "../../../hoc/Card/CustomCard"
import {
    Button,
    FormControl,
    IconButton, Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tooltip
} from "@mui/material"
import Select from "../../Ui/Select/Select"
import SearchIcon from "@mui/icons-material/Search"
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline"
import {ServiceContext} from "../../../context/service/serviceContext"
import KeyValueRow from "../../Ui/KeyValueRow/KeyValueRow"
import Checkbox from "../../Ui/Checkbox/Checkbox"
import TextInput from "../../Ui/TextInput/TextInput"

const Zte = props => {
    const {
        setZteRequestForm, getZteOnuList, getCreateZteOnuForm, setCreateZteOnuForm, sendCreateZteOnuRequest,
        state
    } = useContext(ServiceContext)

    const options = props.devices.map(el => ({id: el.ip, title: el.title}))

    const handleRequestSubmit = () => {
        getZteOnuList()
    }

    const requestChangeHandler = (name, e) => {
        const form = {...state.zteRequestForm, [name]: e.target.value}
        setZteRequestForm(form)
    }

    const setOnuForm = (name, e) => {
        const form = {...state.zteCreateOnuForm, [name]: e.target.value}
        setCreateZteOnuForm(form)
    }

    const handleClickCreateOnu = (no, sn) => {
        getCreateZteOnuForm(no, sn)
    }

    const handleSubmitCreateOnuForm = () => {
        sendCreateZteOnuRequest()
    }

    return <div className={styles.Zte}>
        <div className={styles.row}>
            <div className={styles.col25}>
                <Card title="Запит">
                    <FormControl variant="standard" margin="normal" fullWidth={true}>
                        <div className={styles.group}>
                            <Select
                                options={options}
                                label="Виберіть місто"
                                changeHandler={e => requestChangeHandler('ip' ,e)}
                                value={state.zteRequestForm.ip}
                            />
                        </div>
                        <Button
                            variant="outlined"
                            startIcon={<SearchIcon />}
                            onClick={handleRequestSubmit}
                        >
                            Шукати
                        </Button>
                    </FormControl>
                </Card>
            </div>
            <div className={styles.col75}>
                <Card title="Результат">
                    { state.zteOnuList.length > 0 && <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>#</TableCell>
                                <TableCell align="center">Mac</TableCell>
                                <TableCell align="center" sx={{width: 300}}>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {state.zteOnuList.map((el, idx) => (<TableRow key={idx}>
                                <TableCell align="center">
                                    <strong>
                                        {el.no}
                                    </strong>
                                </TableCell>
                                <TableCell align="center">{el.sn}</TableCell>
                                <TableCell align="center">
                                    {state.zteCreateOnuForm === null || state.zteCreateOnuForm?.sn !== el.sn ? <Tooltip title="Прописати" arrow>
                                        <IconButton
                                            size="large"
                                            color="primary"
                                            aria-label="Прописати"
                                            onClick={() => handleClickCreateOnu(el.no, el.sn)}
                                        >
                                            <AddCircleOutlineIcon />
                                        </IconButton>
                                    </Tooltip>
                                    : <Paper elevation={3} sx={{maxWidth: 300}}>
                                        <FormControl variant="standard" margin="normal" fullWidth={false}>
                                            <KeyValueRow dataKey="Connection Type" dataValue={state.zteCreateOnuForm.connectionType} />
                                            <KeyValueRow dataKey="№ порта olt" dataValue={state.zteCreateOnuForm.portNo} />
                                            <KeyValueRow dataKey="MAC/SN" dataValue={state.zteCreateOnuForm.sn} />
                                            <Checkbox
                                                label="Is Remote Id"
                                                className={styles.my10}
                                                changeHandler={e => setOnuForm('isRemoteId' ,e)}
                                                value={state.zteCreateOnuForm.isRemoteId}
                                            />
                                            <TextInput
                                                value={state.zteCreateOnuForm.vlan}
                                                label="Vlan"
                                                type="number"
                                                fullWidth={false}
                                                className={styles.mb10}
                                                onChange={e => setOnuForm('vlan' ,e)}
                                            />
                                            <Select
                                                label="№ Onu"
                                                value={state.zteCreateOnuForm.onuNo}
                                                options={state.zteCreateOnuForm.availableOnuNo.map(el => ({id: el, title: el}))}
                                                changeHandler={e => setOnuForm('onuNo' ,e)}
                                            />
                                            <Button
                                                variant="contained"
                                                className={styles.my10}
                                                endIcon={<AddCircleOutlineIcon />}
                                                onClick={handleSubmitCreateOnuForm}
                                            >
                                                Прописати
                                            </Button>
                                        </FormControl>
                                    </Paper>}
                                </TableCell>
                            </TableRow>))}
                        </TableBody>
                    </Table>}
                </Card>
            </div>
        </div>
    </div>
}

export default Zte