import React, {useContext, useEffect} from "react"
import {Redirect, Route, Switch} from "react-router-dom"
import Activation from "../../../containers/Activation/Activation"
import Statistics from "../../../containers/Statistics/Statistics"
import Administration from "../../../containers/Administration/Administration"
import Onu from "../../../containers/Onu/Onu"
import Dashboard from "../../../containers/Dashboard/Dashboard"
import Login from "../../../containers/Login/Login"
import {ServiceContext} from "../../../context/service/serviceContext"
import Logout from "../Logout/Logout"
import Customer from "../../../containers/Customer/Customer"
import Requests from "../../../containers/Requests/Requests"

const Router = props => {
    const {isAuthenticated, autoLogin} = useContext(ServiceContext)

    useEffect(() => {
        autoLogin()
        // eslint-disable-next-line
    }, [])

    let nav = (<Switch>
        <Route path={["/", "/login"]} component={Login} />
        <Redirect to="/" />
    </Switch>)

    if (isAuthenticated())
        nav = (<Switch>
            <Route path="/activation" component={Activation}/>
            <Route exact path="/customer" component={Customer}/>
            <Route path="/customer/:login" component={Customer}/>
            <Route path="/requests" component={Requests}/>
            <Route path="/statistics" component={Statistics}/>
            <Route path="/administration" component={Administration} />
            <Route exact path="/onu/" component={Onu}/>
            <Route path="/onu/:type" component={Onu}/>
            <Route path="/logout" component={Logout}/>.
            <Route exact path="/" component={Dashboard}/>.
            <Redirect to="/" />
        </Switch>)


    return nav
}

export default Router