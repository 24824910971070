import React, {useContext, useEffect, useState} from "react"
import CustomCard from "../../../hoc/Card/CustomCard"
import CustomDataGrid from "../../Layout/CustomDataGrid/CustomDataGrid"
import {ServiceContext} from "../../../context/service/serviceContext"
import {Button} from "@mui/material"
import AddIcon from "@mui/icons-material/Add"
import CancelIcon from "@mui/icons-material/Cancel"
import DeviceFrom from "./DeviceFrom"

const Device = () => {
    const {getAllDeviceGroup, getAllDevices, setCreateFromDevice, clearCreateFromDevice, createDevice, updateDevice, deleteDevice, state} = useContext(ServiceContext)

    useEffect(() => {
        clearCreateFromDevice()
        getAllDeviceGroup()
        getAllDevices()
        // eslint-disable-next-line
    }, [])

    const [editRecord, setEditRecord] = useState(false)

    const availableGroups = state.deviceGroupList.map(el => ({id: el.id, title: el.title}))

    const handleAddNewRecordClick = () => {
        clearCreateFromDevice()
        setEditRecord(true)
    }

    const handleCancelAddNewRecordClick = () => {
        setEditRecord(false)
        clearCreateFromDevice()
    }

    const handleChangeForm = (field, event) => {
        let form = {}
        if (field === 'groupId')
        {
            const selectedGroup = state.deviceGroupList.find(x => x.id === event.target.value)
            form = {...state.createDeviceForm, [field]: event.target.value, groupName: selectedGroup?.title, connectionUserName: selectedGroup?.connectionUserName, connectionPassword: selectedGroup?.connectionPassword}
        }
        else
            form = {...state.createDeviceForm, [field]: event.target.value}

        setCreateFromDevice(form)
    }

    const handleClickSave = () => {
        if (state.createDeviceForm.id === 0)
            createDevice()
        else
            updateDevice()

        handleCancelAddNewRecordClick()
    }

    const handleClickEdit = model => {
        setCreateFromDevice(model)
        setEditRecord(true)
    }

    const handleClickDelete = id => {
        deleteDevice(id)
    }

    const columns = [
        { field: 'id', headerName: '#', width: 50 },
        { field: 'title', headerName: 'Title', width: 200 },
        { field: 'groupId', headerName: 'Group Id', width: 200 },
        { field: 'groupName', headerName: 'Group Name', width: 200 },
        { field: 'ip', headerName: 'IP', width: 150 },
        { field: 'protocolType', headerName: 'Protocol', width: 150 },
        { field: 'connectionUserName', headerName: 'User Name', width: 150 },
        { field: 'connectionPassword', headerName: 'Password', width: 150 }
    ]

    return <CustomCard title="Device">
        <div style={{marginBottom: "10px"}}>
            {!editRecord ? <Button color="primary" startIcon={<AddIcon />} onClick={handleAddNewRecordClick}>
                    Додати запис
                </Button>
                : <Button color="warning" startIcon={<CancelIcon />} onClick={handleCancelAddNewRecordClick}>
                    Скасувати
                </Button>}
        </div>
        <div style={{ height: 300, width: '100%'}}>
            {editRecord ? <DeviceFrom
                model={state.createDeviceForm}
                groupList={availableGroups}
                onClickSave={handleClickSave}
                onChangeForm={handleChangeForm}
            /> : <CustomDataGrid
                rows={state.devices}
                columns={columns}
                pageSize={25}
                onEditClick={handleClickEdit}
                onDeleteClick={handleClickDelete}
            />}
        </div>
    </CustomCard>
}

export default Device