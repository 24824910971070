import React from "react"
import {Button, FormControl} from "@mui/material"
import TextInput from "../../Ui/TextInput/TextInput"
import SaveIcon from "@mui/icons-material/Save"
import Checkbox from "../../Ui/Checkbox/Checkbox"

const AdminEditForm = props => {
    const isCanEdit = props.model.id === 0

    const inputGroupCss = {
        marginBottom: '16px'
    }

    return <FormControl variant="standard" margin="normal" fullWidth={true}>
        <div style={inputGroupCss}>
            <div style={{display: 'flex', gap: '10px'}}>
                <TextInput
                    label="First Name"
                    name="firstName"
                    value={props.model.firstName}
                    onChange={e => props.onChangeForm('firstName' ,e)}
                />
                <TextInput
                    label="Last Name"
                    name="lastName"
                    value={props.model.lastName}
                    onChange={e => props.onChangeForm('lastName' ,e)}
                />
                <TextInput
                    label="Surname"
                    name="surname"
                    value={props.model.surname}
                    onChange={e => props.onChangeForm('surname' ,e)}
                />
            </div>
        </div>
        <div style={inputGroupCss}>
            <div style={{display: 'flex', gap: '10px'}}>
                <TextInput
                    label="Phone"
                    name="phone"
                    value={props.model.phone}
                    onChange={e => props.onChangeForm('phone' ,e)}
                />
                {isCanEdit && <TextInput
                    label="Email"
                    name="email"
                    type="email"
                    value={props.model.email}
                    onChange={e => props.onChangeForm('email' ,e)}
                />}
                {isCanEdit && <TextInput
                    label="Password"
                    name="password"
                    value={props.model.connectionPassword}
                    onChange={e => props.onChangeForm('password' ,e)}
                />}
            </div>
        </div>
        <div style={inputGroupCss}>
            {isCanEdit && <TextInput
                label="Login"
                name="login"
                value={props.model.login}
                onChange={e => props.onChangeForm('login' ,e)}
            />}
        </div>
        <div style={inputGroupCss}>
            <Checkbox
                label="isOperator"
                changeHandler={e => props.onChangeForm('isOperator' ,e)}
                value={props.model.isOperator}
            />
        </div>
        <div>
            <Button
                variant="outlined"
                startIcon={<SaveIcon />}
                onClick={props.onClickSave}
            >
                Зберегти
            </Button>
        </div>
    </FormControl>
}

export default AdminEditForm