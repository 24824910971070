import React, {useContext, useState} from "react"
import styles from "./Bdcom.module.scss"
import Card from "../../../hoc/Card/CustomCard"
import Select from "../../Ui/Select/Select"
import {ServiceContext} from "../../../context/service/serviceContext"
import KeyValueRow from "../../Ui/KeyValueRow/KeyValueRow"
import {
    Button,
    FormControl,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tooltip
} from "@mui/material"
import SearchIcon from '@mui/icons-material/Search'
import DeleteIcon from "@mui/icons-material/Delete"
import CachedIcon from '@mui/icons-material/Cached'
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import CheckIcon from '@mui/icons-material/Check'
import TextInput from "../../Ui/TextInput/TextInput";

const Bdcom = props => {
    const {
        setBdcomRequestForm, setBdcomSearchForm, getBdcomOnuList, searchBdcomOnuByMac, getBdcomOnuSignal,
        removeBdcomOnu, addBdcomOnuVlan, reloadBdcomOnu, reloadBdcomPonPort, clearBdcomAllOnu,
        state
    } = useContext(ServiceContext)

    const options = props.devices.map(el => ({id: el.ip, title: el.title}))
    const ports = [1,2,3,4,5,6,7,8].map(el => ({id: el, title: el}))

    const [vlan, setVlan] = useState({showInput: false, currentVlanId: null, vlan: ""})

    const requestChangeHandler = (name, e) => {
        const form = {...state.bdcomRequestForm, [name]: e.target.value}
        setBdcomRequestForm(form)
    }

    const searchChangeHandler = (name, e) => {
        const form = {...state.bdcomSearchForm, [name]: e.target.value}
        setBdcomSearchForm(form)
    }

    const handleRequestSubmit = () => {
        getBdcomOnuList()
    }

    const handleSearchSubmit = () => {
        searchBdcomOnuByMac()
    }

    const clickGetSignalHandler = (id, mac) => {
        getBdcomOnuSignal(id, mac)
    }

    const clickRemoveHandler = (id, mac) => {
        removeBdcomOnu(id, mac)
    }

    const clickAddVlanHandler = (id, mac, vlan) => {
        addBdcomOnuVlan(id, mac, vlan)
        setVlan({...vlan, showInput: false, currentVlanId: null, vlan: ""})
    }

    const clickReloadHandler = (id, mac) => {
        reloadBdcomOnu(id, mac)
    }

    const clickReloadPonPortHandler = () => {
        reloadBdcomPonPort()
    }

    const clickClearPortHandler = () => {
        clearBdcomAllOnu()
    }

    const addVlanChangeHandler = e => {
        setVlan({...vlan, vlan: e.target.value})
    }

    const handleClickAddVlan = (id) => {
        setVlan({...vlan, showInput: true, currentVlanId: id})
    }

    return <div className={styles.Bdcom}>
        <div className={styles.row}>
            <div className={styles.col25}>
                <Card title="Запит">
                    <FormControl variant="standard" margin="normal" fullWidth={true}>
                        <div className={styles.group}>
                            <Select
                                options={options}
                                label="Виберіть місто"
                                changeHandler={e => requestChangeHandler('ip' ,e)}
                                value={state.bdcomRequestForm.ip}
                            />
                        </div>
                        <div className={styles.group}>
                            <Select
                                options={ports} label="Виберіть порт"
                                changeHandler={e => requestChangeHandler('port' ,e)}
                                value={state.bdcomRequestForm.port}
                            />
                        </div>
                        <Button
                            variant="outlined"
                            startIcon={<SearchIcon />}
                            onClick={handleRequestSubmit}
                        >
                            Шукати
                        </Button>
                    </FormControl>
                </Card>
                <Card title="Пошук">
                    <FormControl variant="standard" margin="normal" fullWidth={true}>
                        <div className={styles.group}>
                            <Select
                                options={options}
                                label="Виберіть місто"
                                changeHandler={e => searchChangeHandler('ip' ,e)}
                                value={state.bdcomSearchForm.ip}
                            />
                        </div>
                        <div className={styles.group}>
                            <TextInput
                                label="MAC"
                                name="mac"
                                value={state.bdcomSearchForm.mac}
                                onChange={e => searchChangeHandler('mac' ,e)}
                            />
                        </div>
                        {!!state.searchBdcomOnuByMacResult ? <div className={styles.group}>
                                <KeyValueRow dataKey="Результат" dataValue={state.searchBdcomOnuByMacResult} />
                            </div>
                            : null}
                        <Button
                            variant="outlined"
                            startIcon={<SearchIcon />}
                            onClick={handleSearchSubmit}
                        >
                            Шукати
                        </Button>
                    </FormControl>
                </Card>
            </div>
            <div className={styles.col75}>
                <Card title="Результат">
                    { state.bdcomOnluList.length > 0 && <>
                        <Button
                            variant="outlined"
                            color="warning"
                            startIcon={<CachedIcon />}
                            onClick={clickReloadPonPortHandler}
                        >
                            Reload PON port
                        </Button>
                        &nbsp;
                        <Button
                            variant="outlined"
                            color="error"
                            startIcon={<DeleteIcon />}
                            onClick={clickClearPortHandler}
                        >
                            Clear ALL Onu
                        </Button>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>#</TableCell>
                                    <TableCell align="center">Mac</TableCell>
                                    <TableCell align="center">Signal</TableCell>
                                    <TableCell align="center">Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {state.bdcomOnluList.map((el, idx) => (<TableRow key={idx}>
                                    <TableCell align="right">{el.id}</TableCell>
                                    <TableCell align="center">{el.mac}</TableCell>
                                    <TableCell align="center">{el?.signal}</TableCell>
                                    <TableCell align="center">
                                        <Tooltip title="Add Vlan" arrow>
                                            <IconButton
                                                size="large"
                                                color="primary"
                                                aria-label="Add Vlan"
                                                onClick={e => handleClickAddVlan(el.id)}
                                            >
                                                <AddCircleOutlineIcon />
                                            </IconButton>
                                        </Tooltip>
                                        {vlan.showInput && vlan.currentVlanId === el.id ? <>
                                            <TextInput
                                                label="Add Vlan"
                                                name="vlan"
                                                value={vlan.vlan}
                                                fullWidth={false}
                                                onChange={addVlanChangeHandler}
                                            />
                                            <IconButton
                                                size="large"
                                                color="success"
                                                aria-label="Signal"
                                                onClick={() => clickAddVlanHandler(el.id, el.mac, vlan.vlan)}
                                            >
                                                <CheckIcon />
                                            </IconButton>
                                        </> : null}
                                        <Tooltip title="Signal" arrow>
                                            <IconButton
                                                size="large"
                                                color="success"
                                                aria-label="Signal"
                                                onClick={() => clickGetSignalHandler(el.id, el.mac)}
                                            >
                                                <SignalCellularAltIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Reload" arrow>
                                            <IconButton
                                                size="large"
                                                color="warning"
                                                aria-label="Reload"
                                                onClick={() => clickReloadHandler(el.id, el.mac)}
                                            >
                                                <CachedIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Remove" arrow>
                                            <IconButton
                                                size="large"
                                                color="error"
                                                aria-label="Remove"
                                                onClick={() => clickRemoveHandler(el.id, el.mac)}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>))}
                            </TableBody>
                        </Table>
                    </>}
                </Card>
            </div>
        </div>
    </div>
}

export default Bdcom