import React, {useContext, useEffect} from "react"
import {ServiceContext} from "../../../context/service/serviceContext"
import {Redirect} from "react-router-dom";

const Logout = () => {
    const {authLogout} = useContext(ServiceContext)

    useEffect(() => {
        authLogout()
        // eslint-disable-next-line
    }, [])

    return <Redirect to="/" />
}

export default Logout