import React, {useContext, useEffect, useState} from "react"
import {ServiceContext} from "../../../context/service/serviceContext"
import CustomCard from "../../../hoc/Card/CustomCard"
import {Box, Button, IconButton, Modal, Tooltip, Typography} from "@mui/material"
import AddIcon from "@mui/icons-material/Add"
import CancelIcon from "@mui/icons-material/Cancel"
import CustomDataGrid from "../../Layout/CustomDataGrid/CustomDataGrid"
import DealerEditForm from "./DealerEditForm"
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import TextInput from "../../Ui/TextInput/TextInput";
import CreateIcon from "@mui/icons-material/Create";

const Dealers = () => {
    const {setChangeUserForm, clearChangeUserForm, getAllUsers, createUser, updateUser, deleteUser, userChangePassword, state} = useContext(ServiceContext)

    useEffect(() => {
        clearChangeUserForm()
        getAllUsers()
        // eslint-disable-next-line
    }, [])

    const [editRecord, setEditRecord] = useState(false)

    const [changePassword, setChangePassword] = useState(null)
    const [errorChangePassword, setErrorChangePassword] = useState(null)

    const handleChangePassword = userId => {
        setChangePassword({...state.userPasswordForm, userId: userId})
    }

    const handleSaveChangePassword = form => {
        if (changePassword.newPassword !== changePassword.passwordConfirm)
        {
            setErrorChangePassword("Паролі не співпадають")
            return
        }

        setErrorChangePassword(null)
        userChangePassword(form)
        setChangePassword(null)
    }

    const handleAddNewRecordClick = () => {
        clearChangeUserForm()
        setEditRecord(true)
    }

    const handleCancelAddNewRecordClick = () => {
        setEditRecord(false)
        clearChangeUserForm()
    }

    const handleChangeForm = (field, event) => {
        const form = {...state.userChangeForm, [field]: event.target.value}
        setChangeUserForm(form)
    }

    const handleClickSave = () => {
        if (state.userChangeForm.id === 0)
            createUser("Dealer")
        else
            updateUser()

        handleCancelAddNewRecordClick()
    }

    const handleClickEdit = model => {
        setChangeUserForm(model)
        setEditRecord(true)
    }

    const handleClickDelete = id => {
        deleteUser(id)
    }

    const columns = [
        { field: 'id', headerName: '#', width: 50 },
        { field: 'login', headerName: 'Login', width: 150 },
        {
            field: 'firstName',
            headerName: 'User Name',
            width: 250,
            renderCell: ({row}) => `${row.firstName} ${row.lastName} ${row.surname}`
        },
        { field: 'phone', headerName: 'Phone', width: 150 },
        { field: 'balance', headerName: 'Balance', width: 100 },
        {
            field: 'password',
            headerName: 'Password',
            width: 100,
            sortable: false,
            renderCell: ({row}) => <Tooltip title="Set Password" arrow>
                <IconButton
                    size="large"
                    color="error"
                    aria-label="Set Password"
                    onClick={e => handleChangePassword(row.id)}
                >
                    <SettingsSuggestIcon />
                </IconButton>
            </Tooltip>
        }
    ]

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        backgroundColor: '#fff',
        border: '2px solid #000',
        boxShadow: 24,
        padding: '10px 20px'
    }

    return <CustomCard title="Диллери">
        <div style={{ height: 300, width: '100%', paddingBottom: "50px"}}>
            <div style={{marginBottom: "10px"}}>
                {!editRecord ? <Button color="primary" startIcon={<AddIcon />} onClick={handleAddNewRecordClick}>
                        Додати запис
                    </Button>
                    : <Button color="warning" startIcon={<CancelIcon />} onClick={handleCancelAddNewRecordClick}>
                        Скасувати
                    </Button>}
            </div>
            <div style={{ height: 300, width: '100%'}}>
                {editRecord ? <DealerEditForm
                    model={state.userChangeForm}
                    onClickSave={handleClickSave}
                    onChangeForm={handleChangeForm}
                /> : <CustomDataGrid
                    rows={state.dealerUserList}
                    columns={columns}
                    pageSize={25}
                    onEditClick={handleClickEdit}
                    onDeleteClick={handleClickDelete}
                />}
            </div>
        </div>

        {!!changePassword ? <Modal
            open={!!changePassword}
            onClose={e => setChangePassword(null)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box style={modalStyle}>
                <Typography gutterBottom variant="h6" component="div">
                    Створення паролю
                </Typography>
                {!!errorChangePassword && <Typography gutterBottom variant="h6" component="div" color="error">
                    {errorChangePassword}
                </Typography>}
                <div style={{display: 'flex', gap: '10px', marginTop: '10px'}}>
                    <TextInput
                        label="New Password"
                        name="newPassword"
                        value={modalStyle.newPassword}
                        onChange={e => setChangePassword({...changePassword, newPassword: e.target.value})}
                    />
                </div>
                <div style={{display: 'flex', gap: '10px', marginTop: '10px'}}>
                    <TextInput
                        label="Password Confirm"
                        name="passwordConfirm"
                        value={modalStyle.passwordConfirm}
                        onChange={e => setChangePassword({...changePassword, passwordConfirm: e.target.value})}
                    />
                </div>
                <div style={{display: 'flex', gap: '10px', marginTop: '10px'}}>
                    <Button
                        variant="outlined"
                        startIcon={<CreateIcon />}
                        onClick={() => {
                            handleSaveChangePassword(changePassword)
                        }}
                    >
                        Зберегти
                    </Button>
                </div>
            </Box>
        </Modal> : null}
    </CustomCard>
}

export default Dealers