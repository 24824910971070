import React, {useContext} from "react"
import styles from "./Layout.module.scss"
import Navigation from "../../components/Layout/Navigation/Navigation"
import Alert from "../../components/Layout/Alert/Alert"
import {CallPopup} from "../../components/Layout/CallPopup/CallPopup"
import {ServiceContext} from "../../context/service/serviceContext"

const Layout = props => {
    const {isAuthenticated} = useContext(ServiceContext)

    const authorized = (
        <div className={styles.Layout}>
            <div className={styles.Navigation}>
                <Navigation />
            </div>
            <div className={styles.Content}>
                <Alert />
                {props.children}
                <CallPopup />
            </div>
        </div>
    )

    const notAuthorized = (
        <div className={['no-print', styles.Layout].join(" ")}>
            <div className={styles.ContentFullWidth}>
                <Alert />
                {props.children}
            </div>
        </div>
    )

    return isAuthenticated() ? authorized : notAuthorized
}

export default Layout