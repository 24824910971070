import React, {useState} from "react"
import CustomCard from "../../../hoc/Card/CustomCard"
import CheckIcon from "@mui/icons-material/Check"
import CancelIcon from "@mui/icons-material/Cancel"
import CustomDataGrid from "../../Layout/CustomDataGrid/CustomDataGrid"
import {Box, Button, IconButton, Modal} from "@mui/material"
import MessageIcon from '@mui/icons-material/Message'
import Textarea from "../../Ui/Textarea/Textarea"
import CreateIcon from "@mui/icons-material/Create"
import {NavLink} from "react-router-dom"

const CallHistory = props => {

    const [callComment, setCallComment] = useState(null)

    const handleSetComment = model => {
        setCallComment(model)
    }

    const columns = [
        { field: 'id', headerName: '#', width: 100 },
        {
            field: 'isProcessed',
            headerName: 'Processed',
            width: 100,
            renderCell: ({row}) => row.isProcessed ? <CheckIcon color="success" /> : <CancelIcon color="error" />
        },
        { field: 'createdAtString', headerName: 'Date', width: 200 },
        { field: 'userLogin', headerName: 'Login', width: 150 },
        {
            field: 'userName',
            headerName: 'FIO',
            width: 350,
            renderCell: ({row}) => <NavLink
                    to={`/customer/${row.userLogin}`}
                    exact={true}
                    style={{textDecoration: "none", marginLeft: '8px'}}
                >
                    <span>{row.userName}</span>
            </NavLink>
        },
        { field: 'phoneNumber', headerName: 'Phone', width: 200 },
        { field: 'comment', headerName: 'Comment', width: 400 },
    ]

    if (props.canEdit)
        columns.push({
            field: 'actions',
            headerName: 'Actions',
            sortable: false,
            width: 100,
            cellClassName: 'actions',
            renderCell: (row) => {
                return <>
                    {!row.row.isProcessed ? <IconButton color="success" onClick={() => props.onSetProcessed(row.id)}>
                        <CheckIcon />
                    </IconButton> : null}
                    <IconButton color="warning" onClick={() => handleSetComment(row.row)}>
                        <MessageIcon />
                    </IconButton>
                </>
            }
        })

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        backgroundColor: '#fff',
        border: '2px solid #000',
        boxShadow: 24,
        padding: '10px 20px'
    }

    return <CustomCard  title="Історія дзвінків">
        <div style={{ height: 500, width: '100%'}}>
            <div style={{ height: 500, width: '100%'}}>
                <CustomDataGrid
                    rows={props.data}
                    columns={columns}
                    pageSize={25}
                />
            </div>
        </div>

        {!!callComment ? <Modal
            open={!!callComment}
            onClose={e => setCallComment(null)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box style={modalStyle}>
                <div style={{display: 'flex', gap: '10px'}}>
                    <Textarea
                        label="Коментар"
                        placeholder="Коментар"
                        hasError={false}
                        errorMessage="Коментар"
                        changeHandler={e => setCallComment({...callComment, comment: e.target.value})}
                        value={callComment.comment}
                    />
                </div>
                <div style={{display: 'flex', gap: '10px', marginTop: '10px'}}>
                    <Button
                        variant="outlined"
                        startIcon={<CreateIcon />}
                        onClick={() => {
                            props.onSaveComment(callComment)
                            setCallComment(null)
                        }}
                    >
                        Зберегти
                    </Button>
                </div>
            </Box>
        </Modal> : null}
    </CustomCard>
}

export default CallHistory