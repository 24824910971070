import React, {useContext, useEffect} from "react"
import {NavLink} from "react-router-dom"
import styles from "./Onu.module.scss"
import Bdcom from "../../components/Onu/Bdcom/Bdcom"
import Zte from "../../components/Onu/Zte/Zte"
import Juniper from "../../components/Onu/Juniper/Juniper";
import {ServiceContext} from "../../context/service/serviceContext"
import Loader from "../../components/Ui/Loader/Loader";

const TYPE_BDCOM = 'bdcom'
const TYPE_ZTE = 'zte'
const TYPE_JUNIPER = 'juniper'

// const capitalize = string => {
//     const lower = string.toLowerCase()
//     return string.charAt(0).toUpperCase() + lower.slice(1)
// }

const Onu = props => {
    const type = props.match.params?.type
    const {getAllDevices, state} = useContext(ServiceContext)

    useEffect(() => {
        getAllDevices()
        // eslint-disable-next-line
    }, [])

    const deviceList = state.devices.filter(x => x.groupName.toLowerCase() === type?.toLowerCase())

    return <div className={styles.Onu}>
        <h1>Onu</h1>

        {[TYPE_BDCOM, TYPE_ZTE, TYPE_JUNIPER].map((el, idx) =>
            <NavLink
                key={idx}
                to={`/onu/${el}`}
                exact={true}
                className={styles.link}
                activeClassName={styles.active}
            >
                <span>{el}</span>
            </NavLink>)}

        {state.loading ? <Loader /> :
            type === TYPE_BDCOM ? <Bdcom devices={deviceList} />
                : type === TYPE_ZTE ? <Zte devices={deviceList} />
                    : type === TYPE_JUNIPER ? <Juniper devices={deviceList} /> : null

        }
    </div>
}

export default Onu