import React from "react"
import styles from "./BillingUser.module.scss"
import KeyValueRow from "../../Ui/KeyValueRow/KeyValueRow"
import {Alert} from "@mui/material"
import CustomCard from "../../../hoc/Card/CustomCard"

const BillingUser = props => {
    if (!props.data)
        return null

    // group: 54
    // id: 2

    return <CustomCard title="Дані користувача з білінгу">
        <div className={styles.BillingUser}>
            {props.data.maCs.length === 0 ? <Alert severity="error">Користувач не активований</Alert> : null}
            <KeyValueRow dataKey="User Login" dataValue={props.data.userLogin} />
            <KeyValueRow dataKey="П.І.Б." dataValue={props.data.userFio} />
            <KeyValueRow dataKey="Баланс" dataValue={props.data.balance.toFixed(2) + ' грн'} />
            <KeyValueRow dataKey="" dataValue="" />
            <KeyValueRow dataKey="Місто" dataValue={props.data.city} />
            <KeyValueRow dataKey="Вулиця" dataValue={props.data.street} />
            <KeyValueRow dataKey="Будинок" dataValue={props.data.house} />
            <KeyValueRow dataKey="Квартира" dataValue={props.data.room} />
            <KeyValueRow dataKey="" dataValue="" />
            <KeyValueRow dataKey="MACs" dataValue="" />
            {props.data.maCs.map(mac => <KeyValueRow key={mac} dataKey="" dataValue={mac} />)}
        </div>
    </CustomCard>
}

export default BillingUser