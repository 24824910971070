import React from "react"
import styles from "./PrintRequest.module.scss"
import logo from "./logo.jpg"

const PrintRequest = ({request}) => {
    if (!request)
        return null

    const monthNames = ["січня", "лютого", "березня", "квітня", "травня", "червня",
        "липня", "серпня", "вересня", "жовтня", "листопада", "грудня"
    ];
    const current = new Date()
    const created = new Date(request.createdAt)

    return <div className={styles.PrintRequest}>
        <img className={styles.logo} src={logo} alt="logo" />
        <h4>Наряд на обслуговування № {request.userLogin} від {current.getDate()} {monthNames[current.getMonth()]} {current.getFullYear()}р.</h4>
        <div className={styles.block}><strong>Прізвище та  ім’я: </strong> {request.userFio}</div>
        <div className={styles.block}><strong>Контактний номер телефону: </strong> {request.userPhone}</div>
        <div className={styles.block}><strong>Адреса: </strong>
            <strong>Нас. пункт </strong> {request.city}
            <strong> вул. </strong> {request.street}
            <strong> № буд. </strong> {request.house}
            {!!request.room ? <><strong> № кв. </strong> {request.room}</> : null}
        </div>
        <div className={styles.block}><strong>Заявка прийнята від абонента: </strong> {created.getDate()} {monthNames[created.getMonth()]} {created.getFullYear()}р. {created.getHours()} год {created.getMinutes()} хв.</div>
        <div className={styles.block}><strong>Тип проблеми за повідомленням абонента: </strong> {request.typeName}</div>
        <div className={styles.block}><strong>Додаткова інформація: </strong> <span className={styles.line}>{request.comment}</span></div>
        <div className={styles.block}><span className={styles.line} /></div>
        <div className={styles.block}>
            <strong>Наряд закрито: </strong> "____" ____ {(new Date).getFullYear()}р. ______ год ______ хв.
        </div>
        <div className={styles.block}>
            <strong>Вартість додаткових робіт при виконанні даного наряду: </strong>  _______________грн.
        </div>
        <div className={styles.block}>
            <small>Роботи при виконанні даного наряду проведені належним чином та в повному обсязі, претензій Сторони одна до одної не мають.</small>
        </div>

        <div className={styles.sign}>
            <div>
                <div className={styles.block}>
                    <strong>Представник Провайдера</strong>
                </div>
                <div className={styles.block}>
                    <span className={styles.line} />
                </div>
                <div className={styles.block}>
                    <strong>Посвідчення № </strong>
                    <span className={styles.line} />
                </div>
                <div className={[styles.block, styles.mt20].join(" ")}>
                    <span className={styles.line} />
                </div>
                <div className={styles.block}>
                    <div className={styles.row}>
                        <small>М.П.</small>
                        <small>(підпис)</small>
                    </div>
                </div>
            </div>
            <div>
                <div className={styles.block}>
                    <strong>Абонент (фізична особа)</strong>
                </div>
                <div className={styles.block}>
                    <span className={styles.line} />
                </div>
                <div className={styles.block}>
                    <small>Підпис /  (прізвище та ініціали підписанта)</small>
                </div>
            </div>
        </div>
    </div>
}

export default PrintRequest