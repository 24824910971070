import React from "react"
import styles from "./Navigation.module.scss"
import {NavLink} from "react-router-dom"

const links = [
    {to: '/', label: 'Dashboard', exact: true, iconClasses: 'icon la la-laptop'},
    {to: '/activation', label: 'Активація', exact: false, iconClasses: 'icon la la-power-off'},
    {to: '/onu', label: 'Onu', exact: false, iconClasses: 'icon la la-terminal'},
    {to: '/customer', label: 'Customer', exact: false, iconClasses: 'icon las la-user-shield'},
    {to: '/requests', label: 'Requests', exact: false, iconClasses: 'icon las la-question'},
    {to: '/statistics', label: 'Statistics', exact: false, iconClasses: 'icon la la-chart-pie'},
    {to: '/administration', label: 'Administration', exact: false, iconClasses: 'icon la la-tools'},
    {to: '/logout', label: 'Logout', exact: false, iconClasses: 'icon la la-sign-out'},
]

const Navigation = props => {

    const renderLinks = () =>  {
        return links.map((link, idx) => {
            return <NavLink
                    key={idx}
                    to={link.to}
                    exact={link.exact}
                    className={styles.link}
                    activeClassName={styles.active}
                >
                <span className={styles.icon + ' ' + link.iconClasses}></span>
                <span className={styles.title}>{link.label}</span>
            </NavLink>
        })
    }

    return <div className={styles.Nav}>
        {renderLinks()}
    </div>
}

export default Navigation