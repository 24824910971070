import React from "react"
import {Button, FormControl} from "@mui/material"
import TextInput from "../../Ui/TextInput/TextInput"
import Select from "../../Ui/Select/Select"
import SaveIcon from "@mui/icons-material/Save"

const DeviceFrom = props => {
    const protocolTypeList = [
        {id: 'Epon', title: 'Epon'},
        {id: 'Gpon', title: 'Gpon'},
        {id: 'Router', title: 'Router'}
    ]

    const inputGroupCss = {
        marginBottom: '16px'
    }

    return <FormControl variant="standard" margin="normal" fullWidth={true}>
        <div style={inputGroupCss}>
            <div style={{display: 'flex', gap: '10px'}}>
                <Select
                    options={props.groupList}
                    label="Group"
                    value={props.model.groupId}
                    changeHandler={e => props.onChangeForm('groupId', e)}
                />
                <Select
                    options={protocolTypeList}
                    label="Protocol Type"
                    value={props.model.protocolType}
                    changeHandler={e => props.onChangeForm('protocolType', e)}
                />
            </div>
        </div>
        <div style={inputGroupCss}>
            <div style={{display: 'flex'}}>

            </div>
            <TextInput
                label="Title"
                name="title"
                value={props.model.title}
                onChange={e => props.onChangeForm('title' ,e)}
            />
        </div>
        <div style={inputGroupCss}>
            <div style={{display: 'flex', gap: '10px'}}>
                <TextInput
                    label="IP"
                    name="ip"
                    value={props.model.ip}
                    onChange={e => props.onChangeForm('ip' ,e)}
                />
                <TextInput
                    label="Connection UserName"
                    name="connectionUserName"
                    value={props.model.connectionUserName}
                    onChange={e => props.onChangeForm('connectionUserName' ,e)}
                />
                <TextInput
                    label="Connection Password"
                    name="connectionPassword"
                    value={props.model.connectionPassword}
                    onChange={e => props.onChangeForm('connectionPassword' ,e)}
                />
            </div>
        </div>
        <div>
            <Button
                variant="outlined"
                startIcon={<SaveIcon />}
                onClick={props.onClickSave}
            >
                Зберегти
            </Button>
        </div>
    </FormControl>
}

export default DeviceFrom