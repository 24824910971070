import React, {useContext, useState} from "react"
import styles from "./Login.module.css"
import {ServiceContext} from "../../context/service/serviceContext"
import CustomCard from "../../hoc/Card/CustomCard"
import Checkbox from "../../components/Ui/Checkbox/Checkbox"
import SendIcon from '@mui/icons-material/Send'
import {Button} from "@mui/material"
import TextInput from "../../components/Ui/TextInput/TextInput"

const Login = () => {
    const [state, setState] = useState({
        login: '',
        password: '',
        rememberMe: false
    })
    const {authLogin} = useContext(ServiceContext)

    const inputChangeHandler = (name, e) => {
        let form = {}
        if (e.target.type === "checkbox")
            form = {...state, [name]: e.target.checked}
        else
            form = {...state, [name]: e.target.value}

        setState(form)
    }

    const clickSearchHandler = () => {
        authLogin(state.login, state.password, state.rememberMe)
    }

    return <div className={styles.Login}>
        <CustomCard title="Авторизація">
            <div>
                <TextInput
                    label="Логін"
                    name="login"
                    value={state.login}
                    onChange={e => inputChangeHandler('login' ,e)}
                />
            </div>
            <div className={styles.MarginTop16}>
                <TextInput
                    label="Пароль"
                    type="password"
                    name="password"
                    value={state.password}
                    onChange={e => inputChangeHandler('password' ,e)}
                />
            </div>
            <div className={styles.MarginTop16}>
                <Checkbox
                    label="Запам’ятати мене"
                    changeHandler={e => inputChangeHandler('rememberMe' ,e)}
                    value={state.rememberMe}
                />
            </div>
            <div className={styles.MarginTop16}>
                <Button variant="contained" endIcon={<SendIcon />} onClick={clickSearchHandler}>
                    Вхід
                </Button>
            </div>
        </CustomCard>
    </div>
}

export default Login