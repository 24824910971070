import React from "react"
import styles from "./MacWithoutUsers.module.scss"
import KeyValueRow from "../../Ui/KeyValueRow/KeyValueRow"
import {Button} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check"

const MacWithoutUsers = props => {
    if (!props.data)
        return null

    return <div className={styles.MacWithoutUsers}>
        {props.data.map(el => <KeyValueRow key={el.id} dataKey={el.mac} dataValue={
            <Button
                variant="outlined"
                startIcon={<CheckIcon />}
                onClick={e => props.activateHandler(el.mac)}
            >
                Активувати
            </Button>} />
        )}

    </div>
}

export default MacWithoutUsers