import React from "react"
import TextInput from "../../Ui/TextInput/TextInput"
import Textarea from "../../Ui/Textarea/Textarea"
import {Button, FormControl} from "@mui/material"
import CreateIcon from "@mui/icons-material/Create"
import CustomCard from "../../../hoc/Card/CustomCard"
import Select from "../../Ui/Select/Select"

const NewRequest = props => {
    const inputGroupCss = {
        marginBottom: '16px'
    }

    let requestsTypes = [...props.requestTypes.map(t => ({id: t.id, title: t.title}))]
    if (props.onlyNewConnection)
    {
        requestsTypes = requestsTypes.filter(f => f.title.includes("Заявка на підключення"))
    }
    
    return <CustomCard title="Нове звернення">
        <FormControl variant="standard" margin="normal" fullWidth={true}>
            <div style={inputGroupCss}>
                <div style={{display: 'flex', gap: '10px'}}>
                    <Select
                        options={requestsTypes}
                        label="Виберіть Тип"
                        changeHandler={e => props.inputChangeHandler('createRequestForm', 'type' ,e)}
                        value={props.createRequestForm.type}
                    />
                </div>
            </div>
            <div style={inputGroupCss}>
                <div style={{display: 'flex', gap: '10px'}}>
                    <TextInput
                        label="Vlan"
                        name="type"
                        value={props.createRequestForm.vlan}
                        onChange={e => props.inputChangeHandler('createRequestForm', 'vlan' ,e)}
                    />
                </div>
            </div>
            <div style={inputGroupCss}>
                <div style={{display: 'flex', gap: '10px'}}>
                    <Textarea
                        label="Коментар"
                        placeholder="Коментар"
                        hasError={false}
                        errorMessage="Коментар"
                        changeHandler={e => props.inputChangeHandler('createRequestForm', 'comment' ,e)}
                        value={props.createRequestForm.comment}
                    />
                </div>
            </div>
            <div style={inputGroupCss}>
                <div style={{display: 'flex', gap: '10px'}}>
                    <TextInput
                        label="Phone"
                        name="phone"
                        value={props.createRequestForm.phone}
                        onChange={e => props.inputChangeHandler('createRequestForm', 'phone' ,e)}
                    />
                </div>
            </div>
            {props.onlyNewConnection ? <>
                <div style={inputGroupCss}>
                    <div style={{display: 'flex', gap: '10px'}}>
                        <TextInput
                            label="Login"
                            name="userLogin"
                            value={props.createRequestForm.userLogin}
                            onChange={e => props.inputChangeHandler('createRequestForm', 'userLogin' ,e)}
                        />
                    </div>
                </div>
                <div style={inputGroupCss}>
                    <div style={{display: 'flex', gap: '10px'}}>
                        <TextInput
                            label="FIO"
                            name="userFio"
                            value={props.createRequestForm.userFio}
                            onChange={e => props.inputChangeHandler('createRequestForm', 'userFio' ,e)}
                        />
                    </div>
                </div>
                <div style={inputGroupCss}>
                    <div style={{display: 'flex', gap: '10px'}}>
                        <TextInput
                            label="IP"
                            name="userIp"
                            value={props.createRequestForm.userIp}
                            onChange={e => props.inputChangeHandler('createRequestForm', 'userIp' ,e)}
                        />
                    </div>
                </div>
                <div style={inputGroupCss}>
                    <div style={{display: 'flex', gap: '10px'}}>
                        <TextInput
                            label="City"
                            name="city"
                            value={props.createRequestForm.city}
                            onChange={e => props.inputChangeHandler('createRequestForm', 'city' ,e)}
                        />
                    </div>
                </div>
                <div style={inputGroupCss}>
                    <div style={{display: 'flex', gap: '10px'}}>
                        <TextInput
                            label="Street"
                            name="street"
                            value={props.createRequestForm.street}
                            onChange={e => props.inputChangeHandler('createRequestForm', 'street' ,e)}
                        />
                    </div>
                </div>
                <div style={inputGroupCss}>
                    <div style={{display: 'flex', gap: '10px'}}>
                        <TextInput
                            label="House number"
                            name="house"
                            value={props.createRequestForm.house}
                            onChange={e => props.inputChangeHandler('createRequestForm', 'house' ,e)}
                        />
                    </div>
                </div>
                <div style={inputGroupCss}>
                    <div style={{display: 'flex', gap: '10px'}}>
                        <TextInput
                            label="Room/apartments number"
                            name="room"
                            value={props.createRequestForm.room}
                            onChange={e => props.inputChangeHandler('createRequestForm', 'room' ,e)}
                        />
                    </div>
                </div>
            </> : null}
            <div style={inputGroupCss}>
                <div style={{display: 'flex', gap: '10px'}}>
                    <Button
                        variant="outlined"
                        startIcon={<CreateIcon />}
                        onClick={props.clickCreateHandler}
                    >
                        Створити
                    </Button>
                </div>
            </div>
        </FormControl>
    </CustomCard>
}

export default NewRequest