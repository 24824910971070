import React, {useContext} from "react"
import styles from "./Activation.module.scss"
import CustomCard from "../../hoc/Card/CustomCard"
import Loader from "../../components/Ui/Loader/Loader"
import BillingUser from "../../components/User/BillingUser/BillingUser"
import MacWithoutUsers from "../../components/Activation/MacWithoutUsers/MacWithoutUsers"
import {ServiceContext} from "../../context/service/serviceContext"
import {Button} from "@mui/material"
import SearchIcon from "@mui/icons-material/Search"
import TextInput from "../../components/Ui/TextInput/TextInput"

const Activation = () => {
    const {searchUser, activateUser, clearActivation, setActivationForm, state} = useContext(ServiceContext)

    const inputChangeHandler = (name, e) => {
        const form = {...state.activationForm, [name]: e.target.value}
        setActivationForm(form)
    }

    const clickSearchHandler = () => {
        clearActivation()
        searchUser()
    }

    return <div className={styles.Activation}>
        <h1>Активація</h1>

        <div className={styles.container}>
            <CustomCard title="Пошук">
                <div className={styles.form}>
                    <TextInput
                        label="Номер рахунку або ім’я"
                        name="userAccount"
                        value={state.activationForm.userAccount}
                        onChange={e => inputChangeHandler('userAccount' ,e)}
                    />
                    <TextInput
                        label="MAC"
                        name="mac"
                        value={state.activationForm.mac}
                        onChange={e => inputChangeHandler('mac' ,e)}
                    />
                    <div className={styles.formGroupDown}>
                        <Button
                            variant="outlined"
                            startIcon={<SearchIcon />}
                            onClick={clickSearchHandler}
                        >
                            Шукати
                        </Button>
                    </div>
                </div>
            </CustomCard>

            {state.loading
                ? <CustomCard title="Триває пошуку">
                    <Loader />
                </CustomCard>
                : state.user.hasOwnProperty('billingUser') > 0 ? <div className={styles.result}>
                        <BillingUser data={state.user.billingUser} />
                        <CustomCard title="Результати пошуку по MAC">
                            <MacWithoutUsers activateHandler={activateUser} data={state.user.macWithoutUsers} />
                        </CustomCard>
                    </div>
                : null}

        </div>
    </div>
}

export default Activation