import React from "react"
import styles from "./Administration.module.scss"
import DeviceGroups from "../../components/Administration/DeviceGroups/DeviceGroups"
import Device from "../../components/Administration/Device/Device"
import Dealers from "../../components/Administration/Dealers/Dealers"
import Admins from "../../components/Administration/Admins/Admins"

const Administration = props => {
    return <div className={styles.Administration}>
        <h1>Administration</h1>
        <div className={styles.container}>
            <div className={styles.row}>
                <div className={styles.col50}>
                    <DeviceGroups />
                </div>
                <div className={styles.col50}>
                    <Device />
                </div>
            </div>
            <div className={styles.row}>
                <div className={styles.col50}>
                    <Admins />
                </div>
                <div className={styles.col50}>
                    <Dealers />
                </div>
            </div>
        </div>
    </div>
}

export default Administration