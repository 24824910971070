import React from "react"
import Layout from "./hoc/Layout/Layout"
import {AlertState} from "./context/alert/AlertState"
import {CallState} from "./context/call/CallState"
import {ServiceState} from "./context/service/ServiceState"
import Router from "./components/Layout/Router/Router"

function App() {
    return (
        <AlertState>
            <ServiceState>
                <CallState>
                    <Layout>
                        <Router />
                    </Layout>
                </CallState>
            </ServiceState>
        </AlertState>
    );
}

export default App;
