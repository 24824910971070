import React, {useContext} from "react"
import styles from "./CallPopup.module.scss"
import {CallContext} from "../../../context/call/callContext"

export const CallPopup = () => {

    const {call, hide} = useContext(CallContext)
    if (!call)
        return null

    return <div className={['no-print', styles.CallPopup].join(" ")}>
        <div className={styles.header}>
            <h5>
                <i className="las la-phone-volume" />
                Дзвінок
            </h5>
            <button type="button" onClick={hide}>×</button>
        </div>
        <div className={styles.body}>
            {call.text}
        </div>
    </div>
}