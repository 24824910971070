import React, {useContext, useEffect, useReducer, useState} from "react"
import {HIDE_CALL, SHOW_CALL} from "../types"
import {CallContext} from "./callContext"
import {callReducer} from "./callReducer"
import {HubConnectionBuilder} from "@microsoft/signalr"
import {ServiceContext} from "../service/serviceContext"
import {NavLink} from "react-router-dom"

export const CallState = ({children}) => {
    const [state, dispatch] = useReducer(callReducer, null)
    const [connection, setConnection] = useState(null)
    const {isAuthenticated, isUserOperator, callHistoryGetAllByLogin, callHistoryGetAll} = useContext(ServiceContext)

    const hide = () => dispatch({type: HIDE_CALL})
    const show = (text) => {
        dispatch({
            type: SHOW_CALL,
            payload: {text}
        })
    }

    const isCanSubscribe = isAuthenticated() && isUserOperator()

    useEffect(() => {
        if (!isCanSubscribe)
        {
            if (!connection)
                return

            connection.stop().then(() => {
                console.log('Disconnected!')
            })
            setConnection(null)
            return
        }

        const newConnection = new HubConnectionBuilder()
            .withUrl(process.env.REACT_APP_SIGNAL_R_ENDPOINT)
            .withAutomaticReconnect()
            .build();

        setConnection(newConnection);
        // eslint-disable-next-line
    }, [isCanSubscribe]);

    useEffect(() => {
        if (!connection || !isCanSubscribe)
            return

        connection.start()
            .then(() => {
                connection.invoke("AddToOperatorGroup")
                    .catch(err => console.error(err.toString()))

                console.log('Connected!')

                connection.on('OperatorCall', (message, data) => {
                    if (!data)
                        return null

                    callHistoryGetAllByLogin(data.userLogin)
                    callHistoryGetAll()

                    show(<>
                        <span>Дзвінок від клієнта</span>
                        <NavLink
                            to={`/customer/${data.userLogin}`}
                            exact={true}
                            style={{textDecoration: "none", marginLeft: '8px'}}
                        >
                            <span>{data.userFio}</span>
                        </NavLink>
                    </>)
                });
            })
            .catch(e => console.log('Connection failed: ', e))
        // eslint-disable-next-line
    }, [connection]);

    return <CallContext.Provider value={
        {hide, show, call: state}
    }>
        {children}
    </CallContext.Provider>
}