import React from "react"
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material"

const SelectCustom = props => {
    return <FormControl fullWidth={true}>
        <InputLabel id="custom-select-label">{props.label}</InputLabel>
        <Select
            size="medium"
            labelId="custom-select-label"
            id="custom-select"
            value={!!props.value ? props.value : ""}
            label={props.label}
            onChange={props.changeHandler}
        >
            {props.options.map((el, idx) => <MenuItem key={idx} value={el.id}>{el.title}</MenuItem>)}
        </Select>
    </FormControl>
}

export default SelectCustom