import React from "react"
import styles from "../BillingUser/BillingUser.module.scss"
import KeyValueRow from "../../Ui/KeyValueRow/KeyValueRow"
import CustomCard from "../../../hoc/Card/CustomCard"

const UserSide = props => {
    if (props.data.length === 0)
        return null

    return <CustomCard title="Дані користувача з UserSide">
        <div className={styles.row}>
            {props.data.map((data, i) => {
                return <div key={i}>
                    {Object.keys(data).map((key, idx) => <KeyValueRow key={`${i}_${idx}`} dataKey={key} dataValue={data[key]} />)}
                    <KeyValueRow dataKey="" dataValue="" />
                </div>
            })}
        </div>
    </CustomCard>
}

export default UserSide