import React from "react"
import {Card, CardContent, Typography} from "@mui/material"

const CustomCard = props => {
    return <Card style={{width: '100%'}}>
        <CardContent>
            <Typography gutterBottom variant="h6" component="div">
                {props.title}
            </Typography>
            <Typography component="div" variant="body2" color="text.secondary">
                {props.children}
            </Typography>
        </CardContent>
    </Card>
}

export default CustomCard