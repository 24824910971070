import {HIDE_CALL, SHOW_CALL} from "../types"

const handlers = {
    [SHOW_CALL]: (state, action) => action.payload,
    [HIDE_CALL]: () => null,
    DEFAULT: state => state
}

export const callReducer = (state, action) => {
    const handler = handlers[action.type] || handlers.DEFAULT
    return handler(state, action)
}