import React from "react"
import styles from "./KeyValueRow.module.scss"

const KeyValueRow = props => {
    return <div className={styles.KeyValueRow}>
        <div className={styles.title}>
            {props.dataKey}
        </div>
        <div className={styles.value}>
            {props.dataValue}
        </div>
    </div>
}

export default KeyValueRow