import React, {useContext, useEffect} from "react"
import styles from "./Dashboard.module.scss"
import CustomCard from "../../hoc/Card/CustomCard"
import UserProfile from "../../components/Dashboard/UserProfile/UserProfile"
import {ServiceContext} from "../../context/service/serviceContext"
import CallHistory from "../../components/User/CallHistory/CallHistory";

const Dashboard = props => {
    const {callHistoryGetAll, state} = useContext(ServiceContext)

    useEffect(() => {
        callHistoryGetAll()
        // eslint-disable-next-line
    }, [])

    return <div className={styles.Dashboard}>
        <h1>Dashboard</h1>

        <div className={styles.container}>
            <div className={styles.row}>
                <div className={styles.col25}>
                    <CustomCard title="Профіль">
                        <UserProfile />
                    </CustomCard>
                </div>
                <CustomCard title="test">
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. A, animi aperiam assumenda at eum fuga, ipsa ipsum
                        iste magnam necessitatibus placeat praesentium provident, quaerat ratione saepe sit sunt suscipit
                        voluptate.</p>
                </CustomCard>
            </div>
            <div className={styles.row}>
                <div className={styles.col100}>
                    <CallHistory data={state.callHistory} canEdit={false} />
                </div>
            </div>
        </div>
    </div>
}

export default Dashboard