import React, {useContext} from "react"
import styles from "./UserProfile.module.css"
import KeyValueRow from "../../Ui/KeyValueRow/KeyValueRow"
import {ServiceContext} from "../../../context/service/serviceContext"

const UserProfile = () => {
    const {state} = useContext(ServiceContext)
    const {identity} = state

    return <div className={styles.UserProfile}>
        <KeyValueRow dataKey="Логін" dataValue={identity.login} />
        <KeyValueRow dataKey="Прізвище" dataValue={identity.firstName} />
        <KeyValueRow dataKey="Ім’я" dataValue={identity.lastName} />
        <KeyValueRow dataKey="По-батькові" dataValue={identity.surname} />
        <KeyValueRow dataKey="Оператор" dataValue={identity.isOperator ? "Так" : "Ні"} />
        <KeyValueRow dataKey="Ролі" dataValue={identity.roles.join(" | ")} />
    </div>
}

export default UserProfile