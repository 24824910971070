import React from "react"
import {DataGrid} from "@mui/x-data-grid"
import {IconButton} from "@mui/material"
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'

const CustomDataGrid = props => {
    const pageSize = !!props.pageSize ? props.pageSize : 25

    const columns = [...props.columns]

    if (!!props.onEditClick && !!props.onDeleteClick)
        columns.push({
            field: 'actions',
            headerName: 'Actions',
            sortable: false,
            width: 100,
            cellClassName: 'actions',
            renderCell: (row) => {
                return <>
                    <IconButton color="warning" onClick={() => props.onEditClick(row.row)}>
                        <EditIcon />
                    </IconButton>
                    <IconButton color="error" onClick={() => props.onDeleteClick(row.id)}>
                        <DeleteIcon />
                    </IconButton>
                </>
            }
        })

    return <DataGrid
        rows={props.rows}
        columns={columns}
        pageSize={pageSize}
    />
}

export default CustomDataGrid