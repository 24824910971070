import React, {useContext, useEffect, useState} from "react"
import CustomCard from "../../../hoc/Card/CustomCard"
import CustomDataGrid from "../../Layout/CustomDataGrid/CustomDataGrid"
import {ServiceContext} from "../../../context/service/serviceContext"
import DeviceGroupsFrom from "./DeviceGroupsFrom"
import {Button} from "@mui/material"
import AddIcon from "@mui/icons-material/Add"
import CancelIcon from "@mui/icons-material/Cancel"

const DeviceGroups = () => {
    const {setCreateFromDeviceGroup, clearCreateFromDeviceGroup, getAllDeviceGroup, createDeviceGroup, updateDeviceGroup, deleteDeviceGroup, state} = useContext(ServiceContext)

    useEffect(() => {
        clearCreateFromDeviceGroup()
        getAllDeviceGroup()
        // eslint-disable-next-line
    }, [])

    const [editRecord, setEditRecord] = useState(false)

    const handleAddNewRecordClick = () => {
        clearCreateFromDeviceGroup()
        setEditRecord(true)
    }

    const handleCancelAddNewRecordClick = () => {
        setEditRecord(false)
        clearCreateFromDeviceGroup()
    }

    const handleChangeForm = (field, event) => {
        const form = {...state.createDeviceGroupForm, [field]: event.target.value}
        setCreateFromDeviceGroup(form)
    }

    const handleClickSave = () => {
        if (state.createDeviceGroupForm.id === 0)
            createDeviceGroup()
        else
            updateDeviceGroup()

        handleCancelAddNewRecordClick()
    }

    const handleClickEdit = model => {
        setCreateFromDeviceGroup(model)
        setEditRecord(true)
    }

    const handleClickDelete = id => {
        deleteDeviceGroup(id)
    }

    const columns = [
        { field: 'id', headerName: '#', width: 50 },
        { field: 'title', headerName: 'Title', width: 200 },
        { field: 'deviceType', headerName: 'Device Type', width: 150 },
        { field: 'connectionUserName', headerName: 'User Name', width: 150 },
        { field: 'connectionPassword', headerName: 'Password', width: 150 }
    ]

    return <CustomCard title="Device Groups">
        <div style={{marginBottom: "10px"}}>
            {!editRecord ? <Button color="primary" startIcon={<AddIcon />} onClick={handleAddNewRecordClick}>
                    Додати запис
                </Button>
                : <Button color="warning" startIcon={<CancelIcon />} onClick={handleCancelAddNewRecordClick}>
                    Скасувати
                </Button>}
        </div>
        <div style={{ height: 300, width: '100%'}}>
            {editRecord ? <DeviceGroupsFrom
                model={state.createDeviceGroupForm}
                onClickSave={handleClickSave}
                onChangeForm={handleChangeForm}
            /> : <CustomDataGrid
                rows={state.deviceGroupList}
                columns={columns}
                pageSize={25}
                onEditClick={handleClickEdit}
                onDeleteClick={handleClickDelete}
            />}
        </div>
    </CustomCard>
}

export default DeviceGroups