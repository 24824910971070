import React, {useContext} from "react"
import styles from "./Juniper.module.scss"
import Card from "../../../hoc/Card/CustomCard"
import {ServiceContext} from "../../../context/service/serviceContext"
import {
    Button,
    FormControl,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tooltip
} from "@mui/material"
import Select from "../../Ui/Select/Select"
import SearchIcon from "@mui/icons-material/Search"
import TextInput from "../../Ui/TextInput/TextInput"
import DeleteIcon from "@mui/icons-material/Delete"
import InputMask from 'react-input-mask'

const Juniper = props => {
    const {
        setJuniperRequestForm, getJuniperOnuList, clearJuniperSession,
        state
    } = useContext(ServiceContext)

    const options = props.devices.map(el => ({id: el.ip, title: el.title}))

    const handleRequestSubmit = () => {
        getJuniperOnuList()
    }

    const requestChangeHandler = (name, e) => {
        const form = {...state.juniperRequestForm, [name]: e.target.value}
        setJuniperRequestForm(form)
    }

    const handleClearSessionClick = () => {
        clearJuniperSession()
    }

    return <div className={styles.Juniper}>
        <div className={styles.row}>
            <div className={styles.col25}>
                <Card title="Запит">
                    <FormControl variant="standard" margin="normal" fullWidth={true}>
                        <div className={styles.group}>
                            <Select
                                options={options}
                                label="Виберіть девайс"
                                changeHandler={e => requestChangeHandler('ip' ,e)}
                                value={state.juniperRequestForm.ip}
                            />
                        </div>
                        <div className={styles.group}>
                            <InputMask
                                mask="****.****.****"
                                maskChar={null}
                                value={state.juniperRequestForm.mac}
                                onChange={e => requestChangeHandler('mac' ,e)}
                            >
                                {inputProps => (<TextInput
                                    label="MAC"
                                    name="mac"
                                    {...inputProps}
                                />)}
                            </InputMask>
                        </div>
                        <Button
                            variant="outlined"
                            startIcon={<SearchIcon />}
                            onClick={handleRequestSubmit}
                        >
                            Шукати
                        </Button>
                    </FormControl>
                </Card>
            </div>
            <div className={styles.col75}>
                <Card title="Результат">
                    { state.juniperOnuList.length > 0 && <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">Interface</TableCell>
                                <TableCell align="center">Ip</TableCell>
                                <TableCell align="center">UserName</TableCell>
                                <TableCell align="center">LS:RI</TableCell>
                                <TableCell align="center">Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {state.juniperOnuList.map((el, idx) => (<TableRow key={idx}>
                                <TableCell align="center">{el.interface}</TableCell>
                                <TableCell align="center">{el.ip}</TableCell>
                                <TableCell align="center">{el.userName}</TableCell>
                                <TableCell align="center">{el.lsRi}</TableCell>
                                <TableCell align="center">
                                    <Tooltip title="Очистити сесію" arrow>
                                        <IconButton
                                            size="large"
                                            color="error"
                                            aria-label="Remove"
                                            onClick={handleClearSessionClick}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>))}
                        </TableBody>
                    </Table>}
                </Card>
            </div>
        </div>
    </div>
}

export default Juniper